import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetClientResponse, UpdateClientPayload } from '../../types';
import { http } from '../../utils/http';
import ClientForm, { FormValues } from '../../components/ClientForm';
import useFetch from '../../hooks/useFetch';

type TParams = { clientId: string };
const EditClient: React.FC<WithTranslation> = ({ t }) => {
  const {
    params: { clientId },
  } = useRouteMatch<TParams>();
  const [{ client }, loading] = useFetch<GetClientResponse>(
    `/api/client/${clientId}`,
    {
      success: false,
      client: null,
      claims: [],
    }
  );
  const [formValues, setFormValues] = useState<FormValues>({});
  useEffect(() => {
    if (client) {
      const { details, photo_url, name, id_type, id_no, is_eligible } = client;
      setFormValues({
        name,
        id_type,
        id_no,
        is_eligible,
        photo: photo_url ? `/api/${photo_url}` : undefined,
        ...details,
      });
    }
  }, [client]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  function postUpdate() {
    const formData = new FormData();
    formData.append('client_id', clientId);
    const { photo, name, id_type, id_no, is_eligible, ...details } = formValues;
    Object.entries({
      name,
      id_type,
      id_no,
      is_eligible,
    }).forEach(([key, value]) => {
      if (value) formData.append(key, value.toString());
    });
    formData.append('photo', photo as File);
    formData.append('details', JSON.stringify(details));
    const request = new Request('/api/client', {
      method: 'PUT',
      body: formData,
    });
    return http<UpdateClientPayload>(request)
      .then(({ parsedBody: { success, error, fields_changed } = {} }) => {
        if (success) {
          enqueueSnackbar(t('backend-response:client-update-successful'), {
            variant: 'success',
          });

          // Force the browser cache to refresh if the photo is updated
          if (fields_changed?.includes('photo_path') && client?.photo_url) {
            return http(
              new Request(`/api/${client?.photo_url}`, {
                method: 'GET',
                headers: {
                  'Cache-Control': 'no-cache',
                },
              })
            )
              .catch((e) => {
                console.error(e);
              })
              .then(() => {
                history.push(`/client/${clientId}`);
                return true;
              });
          }

          history.push(`/client/${clientId}`);
          return true;
        } else {
          enqueueSnackbar(t(error || 'backend-response:update-failed'), {
            variant: 'error',
          });
          return false;
        }
      })
      .catch((e) => {
        enqueueSnackbar(t(`error:${e.message}`), {
          variant: 'error',
        });
        return false;
      });
  }
  return (
    <ClientForm
      title={t('update-client')}
      formValues={formValues}
      setFormValues={setFormValues}
      submit={postUpdate}
      submitLabel="update"
      cancelTo={`/client/${clientId}`}
      loading={loading}
    />
  );
};

export default withTranslation()(EditClient);
