import { useTranslation } from 'react-i18next';
import validate, { ValidateJS } from 'validate.js';

const defaultPrettify = validate.prettify;

const useValidate = (): ValidateJS => {
  const { t } = useTranslation();
  validate.validators.presence.options = {
    message: t('error:validate.required'),
  };
  validate.validators.length.options = {
    wrongLength: t('error:validate.wrongLength'),
    tooShort: t('error:validate.tooShort'),
    tooLong: t('error:validate.tooLong'),
  };
  validate.validators.format.options = {
    message: t('error:validate.isInvalid'),
  };
  validate.validators.email.options = {
    message: t('error:validate.isInvalidEmail'),
  };
  validate.validators.equality.options = {
    message: t('error:validate.notEqual'),
  };
  validate.prettify = (str: string): string => {
    return defaultPrettify(t(str));
  };
  return validate;
};

export default useValidate;
