import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import styled from 'styled-components';
import LanguageToggle from '../components/LanguageToggle';
import { LoginResponse } from '../types';
import FormField from '../components/FormField';
import { http } from '../utils/http';
import FormRow from '../components/FormRow';

type HelperText = {
  error: boolean;
  message: string;
};
const ForgotPassword: React.FC<WithTranslation> = ({ t }) => {
  const [helperText, setHelperText] = useState<HelperText | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const [sending, setSending] = useState(false);

  function postResetPasswordGenerateLink() {
    const formData = new FormData();
    formData.append('email', userEmail);
    const request = new Request('/api/volunteer/generate_password_reset_link', {
      method: 'POST',
      body: formData,
    });
    setHelperText(null);
    setSending(true);
    return http<LoginResponse>(request)
      .then(({ parsedBody: { success } = {} }) => {
        if (success) {
          setHelperText({ message: 'password-reset-sent', error: false });
        }
      })
      .catch((e: Error) => {
        setHelperText({ message: `error:${e.message}`, error: true });
      })
      .finally(() => setSending(false));
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LanguageToggle
        style={{ position: 'absolute', top: '1rem', right: '1rem' }}
      />
      <Typography
        variant="h3"
        color="primary"
        gutterBottom
        style={{ marginTop: '4rem' }}
      >
        {t('reset-password')}
      </Typography>
      <FormContainer>
        <FormField
          id="user_email"
          type="email"
          label={t('email')}
          placeholder="email@email.com"
          value={userEmail}
          onChange={(value) => setUserEmail(value)}
          fullWidth
        />
        <FormRow dense>
          <Button
            color="primary"
            variant="contained"
            onClick={postResetPasswordGenerateLink}
            style={{ marginTop: 20 }}
            disabled={sending}
          >
            {t('submit')}
          </Button>
        </FormRow>
        {helperText && (
          <FormRow dense>
            <FormHelperText style={{ textAlign: 'center' }} error={helperText.error}>
              {t(helperText.message)}
            </FormHelperText>
          </FormRow>
        )}
        <FormRow dense>
          <Link to="/login" style={{ margin: 10, display: 'block' }}>
            {t('return-to-login')}
          </Link>
        </FormRow>
      </FormContainer>
    </Box>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default withTranslation()(ForgotPassword);
