import { CSSProperties } from 'react';
import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';

type ConfirmDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: string;
  disabled?: boolean;
  style?: CSSProperties;
} & WithTranslation;
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  onClose,
  onConfirm,
  open,
  title,
  disabled,
  children,
  style = {},
  t,
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      style={{ textAlign: 'center', ...style }}
    >
      <DialogTitle style={{ wordWrap: 'break-word' }}>
        {title || `${t('confirm')}?`}
      </DialogTitle>
      {children && (
        <DialogContent>
          {typeof children === 'string' ? (
            <DialogContentText>{children}</DialogContentText>
          ) : (
            children
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={onConfirm}
          disabled={disabled}
        >
          {t('confirm')}
        </Button>
        <Button color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(ConfirmDialog);
