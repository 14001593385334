import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  BarChart,
  People,
  Schedule,
  SupervisedUserCircle,
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import './App.css';
import { AuthContext } from './context/auth';
import PrivateRoute from './components/PrivateRoute';
import { UserAuthDetails } from './types';
import AppLayout from './components/AppLayout';
import { NavItem } from './components/menu/MainMenu';
import Clients from './pages/Clients';
import Volunteers from './pages/Volunteers';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ClaimQR from './pages/ClaimQR';
import ClaimVerification from './pages/ClaimVerification';
import Registration from './pages/Clients/Registration';
import Client from './pages/Client';
import GivingIcon from './components/icons/GivingIcon';
import EditClient from './pages/Client/EditClient';
import PendingVolunteers from './pages/Volunteers/PendingVolunteers';
import PendingVolunteersProvider from './context/PendingVolunteersProvider';
import { filterNavItems } from './utils';
import PendingVolunteersCount from './components/PendingVolunteersCount';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ClaimHistory from './pages/Stats/ClaimHistory';
import Volunteer from './pages/Volunteer';
import ChangePassword from './pages/Volunteer/ChangePassword';

type WindowWEnv = Window &
  typeof globalThis & { _env_: Record<string, string> };
const navItems = (t: any): NavItem[] => [
  {
    name: t('claim'),
    href: '/claim',
    icon: GivingIcon,
    accessibility: ['viewer', 'editor', 'superadmin'],
  },
  {
    name: t('client-list'),
    href: '/clients',
    icon: People,
    accessibility: ['editor', 'superadmin'],
  },
  {
    name: t('volunteer-list'),
    href: '/volunteers',
    icon: SupervisedUserCircle,
    accessibility: ['superadmin'],
  },
  {
    name: t('pending_approval'),
    href: '/volunteers/pending',
    icon: Schedule,
    accessibility: ['superadmin'],
    badge: PendingVolunteersCount,
  },
  {
    name: t('claim-history'),
    href: '/claim-history',
    icon: BarChart,
    accessibility: ['superadmin'],
  },
];

const App: React.FC<WithTranslation> = (props) => {
  const existingUser = localStorage.getItem('user');
  const [user, setUser] = useState<UserAuthDetails | null>(
    existingUser && JSON.parse(existingUser)
  );

  const setUserLoginDetails = (userLoginDetails: UserAuthDetails | null) => {
    localStorage.setItem(
      'user',
      userLoginDetails ? JSON.stringify(userLoginDetails) : ''
    );
    setUser(userLoginDetails);
  };

  // Populate these values during runtime for production use
  useEffect(() => {
    const { REACT_APP_TITLE, REACT_APP_META_DESCRIPTION } = (window as WindowWEnv)._env_;
    if (REACT_APP_TITLE) document.title = REACT_APP_TITLE;
    if (REACT_APP_META_DESCRIPTION) {
      const description = document.querySelector('meta[name="description"]')
      if (description) (description as any).content=REACT_APP_META_DESCRIPTION;
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUserLoginDetails }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:jwtToken" component={ResetPassword} />
            <PendingVolunteersProvider userRole={user?.userRole}>
              <AppLayout
                nav={filterNavItems(navItems(props.t), user?.userRole)}
              >
                <Switch>
                  <PrivateRoute
                    path="/clients/register"
                    component={Registration}
                    roles={['superadmin', 'editor']}
                  />
                  <PrivateRoute
                    path="/client/:clientId/edit"
                    component={EditClient}
                    exact
                    roles={['superadmin', 'editor']}
                  />
                  <PrivateRoute path="/client/:clientId" component={Client} />
                  <PrivateRoute
                    path="/clients"
                    component={Clients}
                    roles={['superadmin', 'editor']}
                  />
                  <PrivateRoute
                    path="/claim/:clientId"
                    component={ClaimVerification}
                    exact
                  />
                  <PrivateRoute path="/claim" component={ClaimQR} exact />
                  <PrivateRoute
                    path="/volunteers/pending"
                    component={PendingVolunteers}
                    roles={['superadmin']}
                  />
                  <PrivateRoute
                    path="/volunteers"
                    component={Volunteers}
                    roles={['superadmin']}
                  />
                  <PrivateRoute
                    path="/claim-history"
                    component={ClaimHistory}
                    roles={['superadmin']}
                  />
                  <PrivateRoute
                    path="/user/change-password"
                    component={ChangePassword}
                  />
                  <PrivateRoute path="/user" component={Volunteer} />
                  <Route path="*">
                    <Redirect to={user ? '/claim' : '/login'} />
                  </Route>
                </Switch>
              </AppLayout>
            </PendingVolunteersProvider>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </AuthContext.Provider>
  );
};

export default withTranslation()(App);
