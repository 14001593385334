import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Box, Button, FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import LanguageToggle from '../components/LanguageToggle';
import { useAuth } from '../context/auth';
import { LoginResponse } from '../types';
import FormField from '../components/FormField';
import PasswordFormField from '../components/PasswordFormField';
import { http } from '../utils/http';
import FormRow from '../components/FormRow';

const Login: React.FC<WithTranslation> = ({ t }) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { setUserLoginDetails } = useAuth();

  function postLogin() {
    const formData = new FormData();
    formData.append('email', userEmail);
    formData.append('password', password);
    const request = new Request('/api/volunteer/login', {
      method: 'POST',
      body: formData,
    });
    return http<LoginResponse>(request)
      .then(({ parsedBody: { success, approved, user_role, id } = {} }) => {
        if (success && approved && user_role && id) {
          setUserLoginDetails({
            email: userEmail,
            userRole: user_role,
            id,
          });
          setLoggedIn(true);
        } else if (success && !approved) {
          setLoginError('error:login.not-yet-approved');
        } else {
          setLoginError('error:login.failed');
        }
      })
      .catch((e: Error) => {
        setLoginError(`error:${e.message}`);
      });
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LanguageToggle
        style={{ position: 'absolute', top: '1rem', right: '1rem' }}
      />
      <img src="/logos/logo.svg" style={{ height: '40%', maxWidth: '100%' }} />
      <FormContainer>
        <FormField
          id="user_email"
          type="email"
          label={t('email')}
          placeholder="email@email.com"
          value={userEmail}
          onChange={(value) => setUserEmail(value)}
          fullWidth
        />
        <PasswordFormField
          id="current-password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
        <FormRow dense>
          <LinkContainer>
            <Link to="/signup">{t('register')}</Link>
            <Link to="/forgot-password">{t('forgot-password')}</Link>
          </LinkContainer>
        </FormRow>
        <FormRow dense>
          <Button
            color="primary"
            variant="contained"
            onClick={postLogin}
            style={{ marginTop: 20 }}
          >
            {t('login')}
          </Button>
        </FormRow>
        {loginError && (
          <FormRow dense>
            <FormHelperText style={{ textAlign: 'center' }} error>
              {t(loginError)}
            </FormHelperText>
          </FormRow>
        )}
      </FormContainer>
    </Box>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default withTranslation()(Login);
