import i18next, { Callback, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/zh-cn';

import config from './config';

export default i18next;

const momentLocale: Record<string, string> = {
  zh: 'zh-cn',
};
export const changeMoment = (lng = config.i18n.fallbackLng) => {
  const lowerCasedLng = lng.toLowerCase();
  return moment.locale(momentLocale[lowerCasedLng] || lowerCasedLng);
};

export const changeLocale = (
  lng = config.i18n.fallbackLng,
  callback?: Callback
) =>
  i18next.changeLanguage(lng, (error, t) => {
    if (!error) changeMoment(lng);
    if (callback) callback(error, t);
  });

export const init = () =>
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({ ...config.i18n } as InitOptions, (err) => {
      if (err) return console.error(err);
      changeMoment(i18next.language);
    });
