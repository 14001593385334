import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { ClaimVerificationPayload } from '../types';
import { http } from '../utils/http';

type ConditionalFetchResponse = [ClaimVerificationPayload | null, boolean];

function useVerifyClaim(clientId: string): ConditionalFetchResponse {
  const [loading, setLoading] = useState<boolean>(true);
  const [
    verifiedReason,
    setVerified,
  ] = useState<ClaimVerificationPayload | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    const request = new Request(`/api/client/${clientId}/claim/eligibility`, {
      method: 'POST',
    });
    http<ClaimVerificationPayload>(request)
      .then(({ ok, statusText, parsedBody }) => {
        if (ok) {
          setVerified(parsedBody || null);
        } else {
          enqueueSnackbar(statusText, { variant: 'error' });
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, enqueueSnackbar]);

  return [verifiedReason, loading];
}

export default useVerifyClaim;
