import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import QRScanner from '../components/QRScanner';
import { GetClientResponse } from '../types';
import { validURL, validUUID } from '../utils';
import { http } from '../utils/http';

const checkClientExists = (clientId: string) => {
  const request = new Request(`/api/client/${clientId}`, { method: 'GET' });
  return http<GetClientResponse>(request)
    .then(({ parsedBody: { success } = {} }) => {
      if (success) return true;
      return false;
    })
    .catch(() => false);
};

const ClaimQR: React.FC<WithTranslation> = ({ t }) => {
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const onScan = (result: string) => {
    const paths = result.split('/');
    const clientID = paths[paths.length - 1];
    const isValid =
      validURL(result) &&
      paths.length >= 3 &&
      paths[paths.length - 2] == 'claim' &&
      validUUID(clientID);
    if (isValid) {
      checkClientExists(clientID).then((exist) => {
        if (exist) {
          history.push(`/claim/${clientID}`);
        } else {
          setError(t('error:client-does-not-exist'));
        }
      });
    }
  };
  return (
    <Box textAlign="center">
      <QRScanner onScan={onScan} />
      {error ? (
        <Typography variant="h4" color="error">
          {error}
        </Typography>
      ) : (
        <Typography variant="h4">{t('scan-qr')}</Typography>
      )}
    </Box>
  );
};

export default withTranslation()(ClaimQR);
