import { createContext, useContext } from 'react';
import { UserAuthDetails } from '../types';

type Auth = {
  user: UserAuthDetails | null;
  setUserLoginDetails: (userDetails: UserAuthDetails | null) => void;
};

const defaultAuth: Auth = {
  user: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserLoginDetails: () => {},
};
export const AuthContext = createContext(defaultAuth);

export function useAuth() {
  return useContext(AuthContext);
}
