import { Badge, BadgeProps } from '@material-ui/core';
import React from 'react';
import { usePendingVolunteers } from '../context/PendingVolunteersProvider';

const PendingVolunteersCount = (props: BadgeProps) => {
  const { pendingVolunteers } = usePendingVolunteers();
  return (
    <Badge
      badgeContent={pendingVolunteers.length}
      max={9}
      color="primary"
      style={{ zIndex: 1001 }}
      {...props}
    />
  );
};

export default PendingVolunteersCount;
