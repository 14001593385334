import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import MainMenu, { CollapsedSize, NavItem } from './menu/MainMenu';
import LanguageToggle from './LanguageToggle';
import LogoutButton from './LogoutButton';
import { Person } from '@material-ui/icons';

type TAppLayoutProps = {
  nav: NavItem[];
};

const AppLayout: React.FC<TAppLayoutProps> = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const handleHomePage = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleUserProfilePage = useCallback(() => {
    history.push('/user');
  }, [history]);

  return (
    <Root>
      <AppBar
        position="fixed"
        style={{ zIndex: theme.zIndex.drawer + 1, top: 0 }}
      >
        <Toolbar>
          <img
            src="/logos/logo_white.svg"
            style={{ height: '64px', width: '100px' }}
            onClick={handleHomePage}
          />
          <div style={{ flexGrow: 1 }} />
          <LanguageToggle />
          <Tooltip title="User Profile">
            <IconButton onClick={handleUserProfilePage}>
              <Person />
            </IconButton>
          </Tooltip>
          <LogoutButton />
        </Toolbar>
      </AppBar>

      <MainContent>
        <MainMenu items={props.nav} />
        <PageContent backgroundColor={theme.palette.background.default}>
          {props.children}
        </PageContent>
      </MainContent>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  margin-top: 64px;
`;

const PageContent = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  margin-left: ${CollapsedSize}px;
  padding: 1rem;
`;

export default AppLayout;
