import React, { useCallback } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Volunteer, UserRole } from '../../types';
import { http } from '../../utils/http';
import CustomTable from '../../components/CustomTable';
import VolunteerRow from './VolunteerRow';
import { usePendingVolunteers } from '../../context/PendingVolunteersProvider';

// type VolunteersListProps = {};
const PendingVolunteersList: React.FC<WithTranslation> = ({ t }) => {
  const { pendingVolunteers, setPendingVolunteers } = usePendingVolunteers();
  const { enqueueSnackbar } = useSnackbar();

  const approveOrReject = useCallback(
    (volunteerId: string, approve: boolean) => {
      const request = new Request(
        `/api/volunteer/${volunteerId}/${approve ? 'approve' : 'reject'}`,
        {
          method: 'PUT',
        }
      );
      return http<{ success: boolean; user_role: UserRole }>(request)
        .then(({ statusText, parsedBody: { success } = {} }) => {
          if (success) {
            setPendingVolunteers((volunteers) =>
              volunteers.filter((volunteer) => volunteer.id !== volunteerId)
            );
          } else {
            enqueueSnackbar(statusText, { variant: 'error' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: 'error' });
        });
    },
    [enqueueSnackbar, setPendingVolunteers]
  );

  return (
    <CustomTable
      data={pendingVolunteers}
      rowComponent={(volunteer) => (
        <VolunteerRow
          volunteer={volunteer as Volunteer}
          key={`list-row-${(volunteer as Volunteer).id}`}
          onApproveOrReject={approveOrReject}
        />
      )}
      title={`${t('pending_approval')} ${t('volunteer-list')}`}
      page={1}
      search={false}
      selectableRows={false}
      count={pendingVolunteers.length}
      noData={t('no-pending-volunteers')}
    />
  );
};

export default withTranslation()(PendingVolunteersList);
