import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { colors } from '../constants';
import useVerifyClaim from '../hooks/useVerifyClaim';
import ClientCard from './Client/ClientCard';
import Loading from '../components/Loading';
import ConfirmDialog from '../components/ConfirmDialog';
import { http } from '../utils/http';
import WebCamCapture from '../components/WebCamCapture';
import { ClientClaimPayload } from '../types';
import Media from '../components/Media';
import useBreakpoints from '../hooks/useBreakpoints';

type TParams = { clientId: string };

const ClaimVerification: React.FC<WithTranslation> = ({ t }) => {
  const {
    params: { clientId },
  } = useRouteMatch<TParams>();
  const [verified, loading] = useVerifyClaim(clientId);
  const [claiming, setClaiming] = useState<boolean>(false);
  const [claimPhoto, setClaimPhoto] = useState<string | null>(null);
  const { md } = useBreakpoints();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  function postClaim() {
    const formData = new FormData();
    formData.append('client_id', clientId);
    if (claimPhoto) formData.append('photo', claimPhoto);
    const request = new Request('/api/claimlog', {
      method: 'POST',
      body: formData,
    });
    return http<ClientClaimPayload>(request)
      .then(({ parsedBody: { success, message } = {} }) => {
        if (success) {
          enqueueSnackbar(t('backend-response:client-claim-successful'), {
            variant: 'success',
          });
          history.push(`/claim`);
          return true;
        } else {
          enqueueSnackbar(t(message || 'backend-response:claim-failed'), {
            variant: 'error',
          });
          return false;
        }
      })
      .catch((e) => {
        enqueueSnackbar(t(`error:${e.message}`), {
          variant: 'error',
        });
        return false;
      })
      .finally(() => {
        setClaiming(false);
        setClaimPhoto(null);
      });
  }

  if (loading) return <Loading />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {verified?.client && <ClientCard client={verified.client} />}
      {verified?.is_eligible ? (
        <VerifyClaimContainer>
          <CheckCircle
            style={{ color: colors.success, fontSize: md ? '450px' : '60vw' }}
          />
          <Typography variant="h1">{t('claim-eligibility.yes')}</Typography>
        </VerifyClaimContainer>
      ) : (
        <VerifyClaimContainer>
          <Cancel color="error" style={{ fontSize: md ? '450px' : '60vw' }} />
          <Typography variant="h1">{t('claim-eligibility.no')}</Typography>
        </VerifyClaimContainer>
      )}
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ borderRadius: 20 }}
        onClick={() => setClaiming(true)}
      >
        <Typography variant="h1">{t('claim')}</Typography>
      </Button>
      <ConfirmDialog
        title={t('record-claim')}
        open={claiming}
        onClose={() => {
          setClaiming(false);
          setClaimPhoto(null);
        }}
        onConfirm={postClaim}
      >
        {claimPhoto ? (
          <>
            <Media url={claimPhoto} />
            <Button
              onClick={() => setClaimPhoto(null)}
              variant="outlined"
              color="primary"
              style={{ marginTop: 5 }}
            >
              {t('retake')}
            </Button>
          </>
        ) : (
          <WebCamCapture setImage={setClaimPhoto} />
        )}
      </ConfirmDialog>
    </Box>
  );
};

const VerifyClaimContainer = styled(Box)`
  margin-bottom: 2rem;
  text-align: center;
`;

export default withTranslation()(ClaimVerification);
