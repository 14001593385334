import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Edit, Warning } from '@material-ui/icons';
import { isArray } from 'lodash/fp';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FormRow from '../../components/FormRow';
import Loading from '../../components/Loading';
import { formOrdering } from '../../constants';
import useFetch from '../../hooks/useFetch';
import { Volunteer } from '../../types';
import { http } from '../../utils/http';
import EditVolunteerForm from './EditVolunteerForm';
import updateUserRequest from './updateUserRequest';

const VolunteerPage: React.FC<WithTranslation> = ({ t }) => {
  const [{ volunteer: volunteerData }, loading, error] = useFetch<{
    success: boolean;
    volunteer: Volunteer | null;
  }>('/api/me', { success: false, volunteer: null });
  const [volunteer, setVolunteer] = useState<Volunteer | null>(volunteerData);
  const [editing, setEditing] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  useEffect(() => {
    setVolunteer(volunteerData);
  }, [volunteerData]);
  const { enqueueSnackbar } = useSnackbar();
  if (loading) return <Loading />;
  if (error || !volunteer) return <Warning />;
  const { id, photo_url, ...user } = volunteer;
  const renderValue = (field: string, value: any) => {
    if (isArray(value)) {
      return (value as string[]).join(', ');
    }
    if (field === 'date_of_birth') {
      return new Date(value).toDateString();
    }
    return value;
  };
  return (
    <Box textAlign="center">
      <input
        accept="image/*"
        id={`image-upload-${id}`}
        multiple
        type="file"
        onChange={(event) => {
          if (event?.target?.files?.length) {
            const file = event.target.files[0];
            setPhotoLoading(true);
            updateUserRequest(id, { photo: file })
              .then(({ parsedBody: { success, error, volunteer } = {} }) => {
                if (success && volunteer) {
                  // Force the browser cache to refresh if the photo is updated
                  return http(
                    new Request(`/api/${volunteer.photo_url}`, {
                      method: 'GET',
                      headers: {
                        'Cache-Control': 'no-cache',
                      },
                    })
                  )
                    .then(() => setVolunteer(volunteer))
                    .catch((e) => {
                      console.error(e);
                    });
                } else {
                  enqueueSnackbar(
                    t(error || 'backend-response:update-failed'),
                    {
                      variant: 'error',
                    }
                  );
                }
              })
              .catch((e) => {
                enqueueSnackbar(t(`error:${e.message}`), {
                  variant: 'error',
                });
              })
              .finally(() => setPhotoLoading(false));
          }
        }}
        style={{ display: 'none' }}
      />
      <label htmlFor={`image-upload-${id}`}>
        <Box
          position="relative"
          maxWidth={140}
          margin="5px auto"
          width="auto"
          height={140}
        >
          <Avatar
            alt={user.name}
            src={photo_url ? `/api/${photo_url}` : undefined}
            variant="square"
            style={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
          />
          {photoLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.5)',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
      </label>
      <Link to="/user/change-password">{t('change-password')}</Link>
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 10 }}>
        {t('details')}:-
        <IconButton
          color={editing ? 'primary' : 'secondary'}
          onClick={() => setEditing((e) => !e)}
        >
          <Edit />
        </IconButton>
      </Typography>
      {editing ? (
        <Box display="flex" justifyContent="center">
          <EditVolunteerForm
            goBack={() => setEditing(false)}
            values={{
              name: user.name,
              phone_no: user.phone_no || '',
              email: user.email,
              id_no: user.id_no || '',
              id_type: user.id_type || 'malaysian-ic',
            }}
            postUpdate={(updatedValues) =>
              updateUserRequest(id, updatedValues)
                .then(({ parsedBody: { success, error, volunteer } = {} }) => {
                  if (success && volunteer) {
                    // Force the browser cache to refresh if the photo is updated
                    setVolunteer(volunteer);
                    setEditing(false);
                    enqueueSnackbar(
                      t('backend-response:user-update-successful'),
                      {
                        variant: 'success',
                      }
                    );
                  } else {
                    enqueueSnackbar(
                      t(error || 'backend-response:update-failed'),
                      {
                        variant: 'error',
                      }
                    );
                  }
                })
                .catch((e) => {
                  enqueueSnackbar(t(`error:${e.message}`), {
                    variant: 'error',
                  });
                })
            }
          />
        </Box>
      ) : (
        Object.entries(user)
          .sort((a, b) =>
            formOrdering[a[0]]
              ? formOrdering[b[0]]
                ? formOrdering[a[0]] - formOrdering[b[0]]
                : -1
              : 1
          )
          .map(([key, value]) => (
            <FormRow
              key={`${id}-${key}`}
              label={t(`${key}`)}
              labelFlex="0 0 50%"
              contentTextAlign="left"
            >
              {renderValue(key, value)}
            </FormRow>
          ))
      )}
    </Box>
  );
};

export default withTranslation()(VolunteerPage);
