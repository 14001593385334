import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FormField, { FormFieldProps } from './FormField';

type PasswordFormFieldProps = {
  autocomplete?: 'on' | 'off' | 'current-password' | 'new-password';
} & FormFieldProps;
const PasswordFormField: React.FC<PasswordFormFieldProps> = (props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <FormField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        ...(props.inputProps || {}),
        autocomplete: props.autocomplete || 'current-password',
      }}
      label={t('password')}
      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
      {...props}
    />
  );
};

export default PasswordFormField;
