import { Box } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import QrReader from 'react-qr-reader';

type QRScannerProps = {
  onScan?: (result: string) => void;
};
const QRScanner = ({ onScan }: QRScannerProps) => {
  const [legacyMode, setLegacyMode] = useState<boolean>(false);
  const QrRef = useRef<QrReader>(null);
  const handleScan = (data: string | null) => {
    if (data !== null) {
      console.log(data);
      if (onScan) onScan(data);
    }
  };
  const handleError = (err: any) => {
    // alert('Error occured why scanning QR Code. If error persists, try searching client by name or ID.')
    console.error(err);
    if (confirm(err)) {
      setLegacyMode(true);
    }
  };
  const onClick = () => {
    return QrRef.current?.openImageDialog();
  };

  return (
    <Box>
      <QrReader
        ref={QrRef}
        delay={100}
        onError={handleError}
        onScan={handleScan}
        style={{
          display: legacyMode ? 'none' : 'block',
          maxHeight: '80vh',
          maxWidth: '80vh',
          margin: 'auto',
        }}
        legacyMode={legacyMode}
        facingMode="environment"
      />
      {legacyMode && (
        <input type="button" value="Submit QR Code" onClick={onClick} />
      )}
    </Box>
  );
};

export default QRScanner;
