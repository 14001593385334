import React, { ReactNode } from 'react';
import { FormLabel, FormLabelProps } from '@material-ui/core';
import styled from 'styled-components';
import useBreakpoints from '../hooks/useBreakpoints';

export type FormRowProps = {
  labelFlex?: string | number;
  dense?: boolean;
  label?: ReactNode;
  labelProps?: FormLabelProps;
  contentTextAlign?: 'center' | 'left' | 'right' | 'justify';
};

const FormRow: React.FC<FormRowProps> = (props) => {
  const {
    label,
    children,
    labelFlex,
    dense,
    labelProps,
    contentTextAlign = 'center',
  } = props;
  const { sm: matches } = useBreakpoints();

  return (
    <Row>
      {matches ? (
        <Cell textAlign="right" flex={labelFlex || '0 0 150px'} dense={dense}>
          <FormLabel {...labelProps}>{label}</FormLabel>
        </Cell>
      ) : (
        label && (
          <Cell
            textAlign="center"
            flex={'0 0 100%'}
            dense={dense}
            style={{ paddingBottom: 0 }}
          >
            <FormLabel {...labelProps}>{label}</FormLabel>
          </Cell>
        )
      )}
      <Cell dense={dense} textAlign={matches ? contentTextAlign : 'center'}>
        {children}
      </Cell>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
type CellProps = {
  flex?: string | number;
  dense?: boolean;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
};
const Cell = styled.div<CellProps>`
  flex: ${(props) => props.flex || 1};
  padding: ${(props) => (props.dense ? '0 10px' : '10px')};
  text-align: ${(props) => props.textAlign || 'center'};
`;
export default FormRow;
