import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import qs from 'query-string';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { CloudDownload, PersonAdd, RecentActors } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import useFetch from '../../hooks/useFetch';
import { ClientDetail, Paginated } from '../../types';
import {
  useQueryStringStateBoolean,
  useQueryStringStateNumber,
  useQueryStringStateOptionalBoolean,
  useQueryStringStateString,
  useQueryStringStateStringArray,
} from '../../hooks/useQueryStringState';
import { http } from '../../utils/http';
import CustomTable from '../../components/CustomTable';
import ClientRow from './ClientRow';
import useBreakpoints from '../../hooks/useBreakpoints';
import { saveAs } from '../../utils/file';
import usePermission from '../../hooks/usePermission';

// type ClientsListProps = {};
const ClientsList: React.FC<WithTranslation> = ({ t }) => {
  const authorised = usePermission(['superadmin']);
  const { md } = useBreakpoints();
  const [itemsPerPage, setItemsPerPage] = useQueryStringStateNumber(
    'items-per-page',
    10
  );
  const [page, setPage] = useQueryStringStateNumber('page', 1);
  const [sortBy, setSortBy] = useQueryStringStateString(
    'sort-by',
    'created_at'
  );
  const [isAscending, setIsAscending] = useQueryStringStateBoolean(
    'is-ascending',
    false
  );
  const [searchText, setSearchText] = useQueryStringStateString('search', '');
  const [
    cardIssuedFilter,
    setCardIssuedFilter,
  ] = useQueryStringStateOptionalBoolean('card_issued', null);
  const [
    isEligibleFilter,
    setIsEligibleFilter,
  ] = useQueryStringStateOptionalBoolean('is_eligible', null);
  const [selectable, setSelectable] = useQueryStringStateBoolean(
    'selectable',
    false
  );
  const [selectedRows, setSelectedRows] = useQueryStringStateStringArray(
    'selected-rows',
    []
  );

  const [clientlist, loading] = useFetch<Paginated<ClientDetail>>(
    `/api/clients?${qs.stringify({
      items_per_page: itemsPerPage,
      page,
      sort_by: sortBy,
      is_ascending: isAscending,
      search: searchText || undefined,
      card_issued: cardIssuedFilter === null ? undefined : cardIssuedFilter,
      is_eligible: isEligibleFilter === null ? undefined : isEligibleFilter,
    })}`,
    {
      items: [],
      pages: 0,
      returned_page: 0,
      success: false,
      total_items: 0,
      all_ids: [],
    }
  );
  const [clients, setClients] = useState<ClientDetail[]>(clientlist.items);

  useEffect(() => {
    setClients(clientlist.items);
  }, [clientlist]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const toggleEligibility = useCallback(
    (clientId: string) => {
      const request = new Request(`/api/client/${clientId}/eligibility`, {
        method: 'PUT',
      });
      return http<{ success: boolean; is_eligible: boolean }>(request)
        .then(({ statusText, parsedBody: { success, is_eligible } = {} }) => {
          if (success && is_eligible !== undefined) {
            setClients((clients) =>
              clients.map((client) => {
                if (client.id === clientId)
                  return {
                    ...client,
                    is_eligible,
                  };
                return client;
              })
            );
          } else {
            enqueueSnackbar(statusText, { variant: 'error' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const toggleCardIssued = useCallback(
    (clientId: string) => {
      const request = new Request(`/api/client/${clientId}/card_issued`, {
        method: 'PUT',
      });
      return http<{ success: boolean; card_issued: boolean }>(request)
        .then(({ statusText, parsedBody: { success, card_issued } = {} }) => {
          if (success && card_issued !== undefined) {
            setClients((clients) =>
              clients.map((client) => {
                if (client.id === clientId)
                  return {
                    ...client,
                    card_issued,
                  };
                return client;
              })
            );
          } else {
            enqueueSnackbar(statusText, { variant: 'error' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const generateCards = useCallback(() => {
    const request = new Request(`/api/generate-cards`, {
      method: 'POST',
      body: JSON.stringify({
        client_ids: selectedRows,
      }),
      headers: {
        'content-type': 'application/json',
      },
    });
    const win = window.open(); // workaround for safari which ignore window.open from async calls
    return fetch(request)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.blob();
      })
      .then((file) => {
        const fileURL = URL.createObjectURL(file);
        if (win) win.location.href = fileURL;
        setClients((clients) =>
          clients.map((client) => {
            if (selectedRows.includes(client.id))
              return {
                ...client,
                card_issued: true,
              };
            return client;
          })
        );
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      });
  }, [enqueueSnackbar, selectedRows]);

  const downloadClients = useCallback(() => {
    const request = new Request(`/api/clients/download`, {
      method: 'GET',
    });
    return fetch(request)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.blob();
      })
      .then((file) => {
        saveAs(file, 'clients.csv');
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  return (
    <CustomTable
      containerStyle={{ width: md ? '600px' : '270px' }}
      data={clients}
      loading={loading}
      rowComponent={(client, props) => (
        <ClientRow
          client={client as ClientDetail}
          key={`list-row-${(client as ClientDetail).id}`}
          toggleEligibility={!selectable ? toggleEligibility : undefined}
          toggleCardIssued={selectable ? toggleCardIssued : undefined}
          {...props}
        />
      )}
      title={t('client-list')}
      page={page}
      rowsPerPage={itemsPerPage}
      search={true}
      searchText={searchText}
      selectableRows={selectable}
      rowsSelected={selectedRows}
      onRowSelectionChange={setSelectedRows}
      rowsPerPageOptions={[10, 20, 50, 100]}
      boolFilterColumns={(selectable
        ? ['is_eligible', 'card_issued']
        : ['is_eligible']
      ).map((value) => ({
        label: t(value),
        value,
      }))}
      boolFilterValue={{
        is_eligible: isEligibleFilter,
        card_issued: cardIssuedFilter,
      }}
      onBoolColumnFilterChange={(col, value) => {
        if (col === 'is_eligible') setIsEligibleFilter(value);
        if (col === 'card_issued') setCardIssuedFilter(value);
        setPage(1);
      }}
      sortColumns={['name', 'created_at'].map((value) => ({
        label: t(value),
        value,
      }))}
      sortOrder={{ [sortBy]: isAscending ? 'asc' : 'desc' }}
      count={clientlist.total_items}
      onChangeRowsPerPage={(numberOfRows) => setItemsPerPage(numberOfRows)}
      onChangePage={(currentPage) => setPage(currentPage)}
      onColumnSortChange={(changedColumn, direction) => {
        setSortBy(changedColumn);
        setIsAscending(direction === 'asc');
      }}
      onSearchChange={(searchText) => {
        setSearchText(searchText || '');
        setPage(1);
      }}
      allIds={clientlist.all_ids}
      actions={[
        {
          icon: <PersonAdd />,
          onClick: () => {
            history.push(`/clients/register`);
          },
          tooltip: t('register-new-client'),
        },
        {
          icon: <RecentActors color={selectable ? 'primary' : 'secondary'} />,
          onClick: () =>
            setSelectable((s) => {
              if (s) setSelectedRows([]);
              return !s;
            }),
          tooltip: t('generate-cards-mode'),
        },
        {
          icon: <CloudDownload />,
          onClick: downloadClients,
          tooltip: t('download-clients'),
          hidden: !authorised,
        },
        {
          icon: (
            <Button variant="contained" color="primary">
              {t('generate-cards')}
            </Button>
          ),
          onClick: generateCards,
          tooltip: t('generate-cards'),
          selectedOnly: true,
        },
      ]}
    />
  );
};

export default withTranslation()(ClientsList);
