import React, { useCallback, useEffect, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import useFetch from '../../hooks/useFetch';
import { ClientDetail, GetClientResponse } from '../../types';
import ClientCard from './ClientCard';
import ClientDetailView from './ClientDetailView';
import useBreakpoints from '../../hooks/useBreakpoints';
import CustomizedTimeline from '../../components/CustomizedTimeline';
import { Fastfood } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { http } from '../../utils/http';
import { formatDateTime } from '../../utils/datetime';

type TParams = { clientId: string };
const Client = ({ t }: WithTranslation) => {
  const {
    params: { clientId },
  } = useRouteMatch<TParams>();
  const { md } = useBreakpoints();
  const [{ client: clientData, claims }, loading] = useFetch<GetClientResponse>(
    `/api/client/${clientId}`,
    {
      success: false,
      client: null,
      claims: [],
    }
  );
  const [client, setClient] = useState<ClientDetail | null>(clientData || null);
  useEffect(() => {
    if (clientData) setClient(clientData);
  }, [clientData]);

  const { enqueueSnackbar } = useSnackbar();

  const toggleEligibility = useCallback(() => {
    const request = new Request(`/api/client/${clientId}/eligibility`, {
      method: 'PUT',
    });
    return http<{ success: boolean; is_eligible: boolean }>(request)
      .then(({ statusText, parsedBody: { success, is_eligible } = {} }) => {
        if (success && is_eligible !== undefined) {
          setClient((client) =>
            client
              ? {
                  ...client,
                  is_eligible,
                }
              : null
          );
        } else {
          enqueueSnackbar(statusText, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      });
  }, [enqueueSnackbar, clientId]);

  const toggleCardIssued = useCallback(() => {
    const request = new Request(`/api/client/${clientId}/card_issued`, {
      method: 'PUT',
    });
    return http<{ success: boolean; card_issued: boolean }>(request)
      .then(({ statusText, parsedBody: { success, card_issued } = {} }) => {
        if (success && card_issued !== undefined) {
          setClient((client) =>
            client
              ? {
                  ...client,
                  card_issued,
                }
              : null
          );
        } else {
          enqueueSnackbar(statusText, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      });
  }, [enqueueSnackbar, clientId]);

  const generateCard = useCallback(() => {
    const request = new Request(`/api/generate-cards`, {
      method: 'POST',
      body: JSON.stringify({
        client_ids: [clientId],
      }),
      headers: {
        'content-type': 'application/json',
      },
    });
    const win = window.open(); // workaround for safari which ignore window.open from async calls
    return fetch(request)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.blob();
      })
      .then((file) => {
        const fileURL = URL.createObjectURL(file);
        if (win) win.location.href = fileURL;
        setClient((client) =>
          client
            ? {
                ...client,
                card_issued: true,
              }
            : null
        );
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: 'error' });
      });
  }, [enqueueSnackbar, clientId]);

  if (loading || !client) return <Loading />;
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={md ? '576px' : '270px'}>
        <Box display="flex" justifyContent="space-between">
          <RouterLink to={`/client/${clientId}/edit`}>{t('edit')}</RouterLink>
          <Link style={{ cursor: 'pointer' }} onClick={generateCard}>
            {t('generate-card')}
          </Link>
        </Box>
        <ClientCard
          client={client}
          showClaimButton
          showEligibility
          toggleCardIssued={toggleCardIssued}
          showCardIssued
          toggleEligibility={toggleEligibility}
        />
        <ClientDetailView client={client} />
        <CustomizedTimeline
          items={
            claims?.map((c) => ({
              time: formatDateTime(c.claimed_at),
              icon: c.photo_url ? (
                <img
                  src={`/api/${c.photo_url}`}
                  style={{ maxHeight: '50px', borderRadius: '50%' }}
                />
              ) : (
                <Fastfood />
              ),
              content: (
                <div style={{ minHeight: c.photo_url ? '80px' : undefined }} />
              ),
            })) || []
          }
        />
      </Box>
    </Box>
  );
};

export default withTranslation()(Client);
