import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserRole, Volunteer } from '../types';

type PendingVolunteersState = {
  pendingVolunteers: Volunteer[];
  setPendingVolunteers: Dispatch<SetStateAction<Volunteer[]>>;
};

const defaultState: PendingVolunteersState = {
  pendingVolunteers: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPendingVolunteers: () => {},
};
export const PendingVolunteersContext = createContext(defaultState);

export function usePendingVolunteers() {
  return useContext(PendingVolunteersContext);
}

const PendingVolunteersProvider: React.FC<{ userRole?: UserRole }> = ({
  children,
  userRole,
}) => {
  const [volunteers, setVolunteers] = useState<Volunteer[]>([]);

  useEffect(() => {
    if (userRole === 'superadmin') {
      fetch(`/api/volunteers/pending_approval`).then((response) => {
        if (response.ok) {
          return response.json().then((responseData) => {
            setVolunteers(responseData.result);
          });
        }
      });
    }
  }, [userRole]);

  return (
    <PendingVolunteersContext.Provider
      value={{
        pendingVolunteers: volunteers,
        setPendingVolunteers: setVolunteers,
      }}
    >
      {children}
    </PendingVolunteersContext.Provider>
  );
};

export default PendingVolunteersProvider;
