import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@material-ui/core';
import styled from 'styled-components';
import useValidate from '../../hooks/useValidate';
import { http } from '../../utils/http';
import PasswordFormField from '../../components/PasswordFormField';
import { ChangeUserPasswordResponse } from '../../types';
import { useSnackbar } from 'notistack';
import { PASSWORD_CONSTRAINT } from '../../constants';
import { useAuth } from '../../context/auth';

type FormValues = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};
type FormErrors = Record<keyof FormValues, string | null>;
const constraints = {
  oldPassword: {
    presence: true,
    ...PASSWORD_CONSTRAINT,
  },
  password: {
    presence: true,
    ...PASSWORD_CONSTRAINT,
  },
  confirmPassword: {
    equality: 'password',
  },
};

const ChangePassword: React.FC<WithTranslation> = ({ t }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [formValues, setFormValues] = useState<FormValues>({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    oldPassword: null,
    password: null,
    confirmPassword: null,
  });
  const validate = useValidate();
  const { enqueueSnackbar } = useSnackbar();

  const handleOldPasswordChange = (value: string) => {
    const error =
      validate(
        { oldPassword: value },
        { oldPassword: constraints.oldPassword }
      ) || {};
    console.log(error);
    const errors = { ...formErrors, oldPassword: error.oldPassword };
    setFormErrors(errors);
    setFormValues((formValues) => ({ ...formValues, oldPassword: value }));
  };
  const handleNewPasswordChange = (key: keyof FormValues, value: string) => {
    const newFormValues = { ...formValues, [key]: value };
    const check = {
      password: newFormValues.password !== '' ? newFormValues.password : null,
      confirmPassword: newFormValues.confirmPassword,
    };
    const constraint = {
      password: constraints.password,
      confirmPassword: constraints.confirmPassword,
    };
    const error = validate(check, constraint) || {};
    const currentError = {
      password: error.password,
      confirmPassword: error.confirmPassword,
    };
    const errors = { ...formErrors, ...currentError };
    setFormErrors(errors);
    setFormValues(newFormValues);
  };

  function changePasswordRequest() {
    if (user) {
      const formData = new FormData();
      formData.append('new_password', formValues.password);
      formData.append('old_password', formValues.oldPassword);
      const request = new Request(`/api/volunteer/${user.id}/change_password`, {
        method: 'POST',
        body: formData,
      });
      return http<ChangeUserPasswordResponse>(request)
        .then(({ parsedBody: { success, error } = {} }) => {
          if (success) {
            enqueueSnackbar(
              t('backend-response:volunteer-change-password.successful'),
              {
                variant: 'success',
              }
            );
            history.push('/user');
          } else {
            enqueueSnackbar(
              `${t('backend-response:volunteer-change-password.failed')}${
                error ? `: ${error}` : ''
              }`,
              {
                variant: 'error',
              }
            );
          }
        })
        .catch((e) => {
          enqueueSnackbar(
            `${t('backend-response:volunteer-change-password.failed')}: ${e}`,
            {
              variant: 'error',
            }
          );
        });
    }
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Typography
        variant="h2"
        color="primary"
        gutterBottom
        style={{ marginTop: '4rem' }}
      >
        {t('change-password')}
      </Typography>
      <Card variant="outlined" style={{ padding: 10 }}>
        <FormContainer>
          <Typography variant="caption">{t('required-fields')}</Typography>
          <PasswordFormField
            id="current-password"
            required
            error={!!formErrors.oldPassword}
            helperText={formErrors.oldPassword}
            label={t('old-password')}
            value={formValues.oldPassword}
            onChange={handleOldPasswordChange}
            fullWidth
          />
          <PasswordFormField
            id="new-password"
            autocomplete="new-password"
            required
            error={!!formErrors.password}
            helperText={formErrors.password}
            label={t('new-password')}
            value={formValues.password}
            onChange={(value) => handleNewPasswordChange('password', value)}
            fullWidth
          />
          <PasswordFormField
            id="new-password-confirm"
            autocomplete="new-password"
            required
            error={!!formErrors.confirmPassword}
            helperText={formErrors.confirmPassword}
            label={t('confirm-new-password')}
            value={formValues.confirmPassword}
            onChange={(value) =>
              handleNewPasswordChange('confirmPassword', value)
            }
            fullWidth
          />
          <FormFooter>
            <Button
              color="primary"
              variant="contained"
              disabled={validate(formValues, constraints)}
              onClick={changePasswordRequest}
              style={{ marginTop: 20 }}
            >
              {t('change-password')}
            </Button>
            <Link to="/user" style={{ margin: 10, display: 'block' }}>
              {t('cancel')}
            </Link>
          </FormFooter>
        </FormContainer>
      </Card>
    </Box>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withTranslation()(ChangePassword);
