import { IdType, Volunteer } from '../../types';
import { http } from '../../utils/http';

export type UpdateVolunteerPayload = {
  photo?: File;
  name?: string;
  email?: string;
  id_type?: IdType;
  id_no?: string;
  phone_no?: string;
};
type UpdateVolunteerRequest = {
  success: boolean;
  volunteer: Volunteer;
  error?: string;
};
export default function updateUserRequest(
  id: string,
  updatedValues: UpdateVolunteerPayload
) {
  const formData = new FormData();
  const { photo, ...values } = updatedValues;
  Object.entries(values).forEach(([key, value]) => {
    if (value) formData.append(key, value.toString());
  });
  if (photo) formData.append('photo', photo);
  const request = new Request(`/api/volunteer/${id}`, {
    method: 'POST',
    body: formData,
  });
  return http<UpdateVolunteerRequest>(request);
}
