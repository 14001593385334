import { Box, IconButton } from '@material-ui/core';
import { Cancel, Image } from '@material-ui/icons';
import React, { CSSProperties, useEffect, useState } from 'react';

type MediaProps = {
  file?: File;
  url?: string;
  onRemove?: () => void;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  className?: string;
};
const Media = ({
  file,
  url,
  style = {},
  containerStyle,
  className,
  onRemove,
}: MediaProps) => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSrc((e.target?.result as string) || null);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  // if (!src) return <Loading />;
  const renderMedia = () => {
    if (url)
      return (
        <img
          src={url}
          style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
          className={className}
        />
      );
    if (file?.type.startsWith('image') && src)
      return (
        <img
          src={src}
          alt={file.name}
          style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
          className={className}
        />
      );
    if (file?.type.startsWith('video') && src)
      return (
        <video
          src={src}
          style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
          className={className}
        />
      );
    if (file) return <p>{file.name}</p>;
    return (
      <Image
        style={{ maxWidth: '100%', maxHeight: '100%', ...style }}
        className={className}
      />
    );
  };
  return (
    <Box style={{ height: '100%', width: '100%', ...containerStyle }}>
      {onRemove && (
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
          style={{ position: 'absolute', top: 0, right: 0, padding: 0 }}
        >
          <Cancel />
        </IconButton>
      )}
      {renderMedia()}
    </Box>
  );
};

export default Media;
