import { NavItem } from '../components/menu/MainMenu';
import { UserRole } from '../types';

export const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const validUUID = (str: string) => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return !!pattern.test(str);
};

export const filterNavItems = (
  navItems: NavItem[],
  userRole: UserRole = 'viewer'
): NavItem[] =>
  navItems
    .filter((i) => !i.accessibility || i.accessibility.includes(userRole))
    .map((i) =>
      i.items
        ? {
            ...i,
            items: filterNavItems(i.items, userRole),
          }
        : i
    );
