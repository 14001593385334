import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  Clear,
  CloudOff,
  Search,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { debounce } from 'lodash';
import React, { CSSProperties, useCallback, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Loading from './Loading';

type RowProps = {
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
};
type Action = {
  icon: React.ReactElement;
  onClick: () => void;
  tooltip?: React.ReactNode;
  selectedOnly?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};
type SortDirection = 'asc' | 'desc';
type CustomTableProps<T> = {
  data: T[];
  loading?: boolean;
  rowComponent: (row: T, props: RowProps) => JSX.Element;
  title?: string;
  page?: number;
  rowsPerPage?: number;
  search?: boolean;
  searchText?: string;
  searchPlaceholder?: string;
  boolFilterColumns?: {
    label: string;
    value: string;
  }[];
  boolFilterValue?: Record<string, boolean | null>;
  rowsPerPageOptions?: number[];
  sortColumns?: {
    label: string;
    value: string;
  }[];
  sortOrder?: Record<string, SortDirection>;
  count: number;
  selectableRows?: boolean;
  rowsSelected?: string[];
  onChangeRowsPerPage?: (numberOfRows: number) => void;
  onChangePage?: (currentPage: number) => void;
  onColumnSortChange?: (
    changedColumn: string,
    direction: SortDirection
  ) => void;
  onBoolColumnFilterChange?: (
    changedColumn: string,
    value: boolean | null
  ) => void;
  onSearchChange?: (searchText: string) => void;
  onRowSelectionChange?: (selection: string[]) => void;
  noData?: string;
  actions?: Action[];
  allIds?: string[];
  containerStyle?: CSSProperties;
} & WithTranslation;
function CustomTable<T extends { id: string }>({
  t,
  title,
  loading = false,
  data,
  rowComponent,
  sortColumns,
  onColumnSortChange,
  sortOrder,
  search = true,
  searchText,
  onSearchChange,
  searchPlaceholder,
  count,
  page = 1,
  onChangePage,
  rowsPerPage,
  noData,
  actions,
  selectableRows,
  rowsSelected,
  onRowSelectionChange,
  allIds,
  containerStyle,
  boolFilterColumns,
  boolFilterValue,
  onBoolColumnFilterChange,
}: CustomTableProps<T>) {
  const [searchValue, setSearchValue] = useState<string>(searchText || '');
  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      if (onSearchChange)
        debounce(onSearchChange, 500, {
          leading: false,
          maxWait: 1000,
          trailing: true,
        })(value);
    },
    [onSearchChange]
  );

  const renderAction = (
    { icon, onClick, tooltip, disabled }: Action,
    i: number
  ) => {
    let content = (
      <IconButton onClick={onClick} style={{ padding: 8 }} disabled={disabled}>
        {icon}
      </IconButton>
    );
    if (tooltip) {
      content = <Tooltip title={tooltip}>{content}</Tooltip>;
    }
    return <React.Fragment key={`actions-${i}`}>{content}</React.Fragment>;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      marginX="auto"
      style={containerStyle}
    >
      {title && (
        <Typography variant="h1" style={{ marginBottom: '1.5rem' }}>
          {title}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        {search && (
          <TextField
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={searchPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
        {actions
          ?.filter(({ selectedOnly, hidden }) => !selectedOnly && !hidden)
          .map(renderAction)}
      </Box>
      {selectableRows && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width="100%"
          marginY={2}
        >
          <Typography variant="h5">
            {t('item-selected', { count: rowsSelected?.length || 0 })}
          </Typography>
          {actions
            ?.filter(({ selectedOnly, hidden }) => selectedOnly && !hidden)
            .map(renderAction)}
        </Box>
      )}
      <Loading loading={loading}>
        <>
          {onColumnSortChange && (
            <Box
              display="flex"
              marginTop={2}
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              <Typography>{t('sort-by')}:</Typography>
              <span style={{ display: 'inline-flex' }}>
                {sortColumns?.map(({ label, value: col }) => (
                  <span
                    key={`sort-${col}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: `0 10px`,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      onColumnSortChange(
                        col,
                        sortOrder && sortOrder[col] === 'asc' ? 'desc' : 'asc'
                      )
                    }
                  >
                    <Typography style={{ fontWeight: 500 }}>{label}</Typography>
                    {sortOrder &&
                      sortOrder[col] !== undefined &&
                      (sortOrder[col] === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      ))}
                  </span>
                ))}
              </span>
            </Box>
          )}
          {onBoolColumnFilterChange && (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              <Typography>{t('filter-by')}:</Typography>
              <span style={{ display: 'inline-flex' }}>
                {boolFilterColumns?.map(({ label, value: col }) => (
                  <Button
                    color={
                      boolFilterValue && boolFilterValue[col] !== null
                        ? 'primary'
                        : 'secondary'
                    }
                    key={`sort-${col}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: `0 10px`,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      onBoolColumnFilterChange(
                        col,
                        boolFilterValue
                          ? boolFilterValue[col]
                            ? false
                            : boolFilterValue[col] === false
                            ? null
                            : true
                          : true
                      )
                    }
                  >
                    <Typography style={{ fontWeight: 500 }}>{label}</Typography>
                    {boolFilterValue &&
                      boolFilterValue[col] !== null &&
                      (boolFilterValue[col] ? <Check /> : <Clear />)}
                  </Button>
                ))}
              </span>
            </Box>
          )}
          {selectableRows && onRowSelectionChange && (
            <Box display="flex" alignItems="center" marginTop={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rowsSelected?.length === count}
                    onChange={(e) =>
                      onRowSelectionChange(
                        e.target.checked ? allIds || data.map((d) => d.id) : []
                      )
                    }
                    name="select-all"
                  />
                }
                label={t('select-all')}
              />
            </Box>
          )}
          {!data.length && (
            <Box marginY="18vh">
              <CloudOff style={{ fontSize: '20vh', color: '#ccc' }} />
              <Typography variant="h4" style={{ color: '#ccc' }}>
                {noData || t('no-data')}
              </Typography>
            </Box>
          )}
          {data.map((row) =>
            rowComponent(row, {
              selectable: selectableRows,
              selected: rowsSelected?.includes(row.id),
              onSelect: (selected) => {
                if (onRowSelectionChange) {
                  onRowSelectionChange(
                    selected
                      ? [...(rowsSelected || []), row.id].filter(
                          (v, i, a) => a.indexOf(v) === i
                        )
                      : rowsSelected?.filter((r) => r !== row.id) || []
                  );
                }
              },
            })
          )}
        </>
      </Loading>
      {count > 0 && rowsPerPage && onChangePage && (
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          onChange={(e, page) => onChangePage(page)}
          color="primary"
          showFirstButton
          showLastButton
          style={{ marginTop: 10 }}
        />
      )}
    </Box>
  );
}

export default withTranslation()(CustomTable);
