import React from 'react';
import {
  TextField,
  TextFieldProps,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import FormRow, { FormRowProps } from './FormRow';
import { FieldType } from '../types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withTranslation, WithTranslation } from 'react-i18next';
import Media from './Media';
import useBreakpoints from '../hooks/useBreakpoints';

export type FormFieldProps = {
  id: string;
  fieldType?: FieldType;
  options?: {
    label: string;
    value: string | number;
  }[];
  onChange: (value: any) => void;
} & FormRowProps &
  Omit<TextFieldProps, 'onChange'>;

const FormField: React.FC<WithTranslation & FormFieldProps> = (props) => {
  const {
    id,
    label,
    value,
    variant,
    labelFlex,
    dense,
    fieldType = 'short_text',
    options,
    t,
    tReady, // eslint-disable-line @typescript-eslint/no-unused-vars
    onChange,
    ...rest
  } = props;
  const { sm: matches } = useBreakpoints();

  const render = () => {
    if (fieldType === 'short_text')
      return (
        <TextField
          id={id}
          variant={variant || 'outlined'}
          label={matches ? undefined : label}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          {...rest}
        />
      );
    if (fieldType === 'paragraph')
      return (
        <TextField
          id={id}
          multiline
          rows={3}
          variant={variant || 'outlined'}
          label={matches ? undefined : label}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          {...rest}
        />
      );
    if (fieldType === 'single_select')
      return (
        <TextField
          id={id}
          select
          variant={variant || 'outlined'}
          label={matches ? undefined : label}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          {...rest}
        >
          {options?.map(({ label, value }) => (
            <MenuItem key={`options-${id}-${value}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      );
    if (fieldType === 'multiselect') {
      const values = value as (string | number)[] | undefined;
      return (
        <FormControl id={id} required error={rest.error} component="fieldset">
          {!matches && <FormLabel component="legend">{label}</FormLabel>}
          <FormGroup>
            {options?.map(({ label, value }) => (
              <FormControlLabel
                key={`options-${id}-${value}`}
                control={
                  <Checkbox
                    checked={values?.includes(value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (!values?.includes(e.target.name))
                          onChange(
                            values
                              ? [...values, e.target.name]
                              : [e.target.name]
                          );
                      } else {
                        onChange(
                          values
                            ? values.filter((v) => v !== e.target.name)
                            : []
                        );
                      }
                    }}
                    name={value.toString()}
                  />
                }
                label={label}
              />
            ))}
          </FormGroup>
          {rest.helperText && (
            <FormHelperText error={rest.error}>
              {rest.helperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
    if (fieldType === 'boolean')
      return (
        <FormControl id={id} required error={rest.error} component="fieldset">
          {!matches && <FormLabel component="legend">{label}</FormLabel>}
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(e, value) => onChange(value)}
          >
            <ToggleButton value={true}>{t('yes')}</ToggleButton>
            <ToggleButton value={false}>{t('no')}</ToggleButton>
          </ToggleButtonGroup>
          {rest.helperText && (
            <FormHelperText error={rest.error}>
              {rest.helperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    if (fieldType === 'image')
      return (
        <FormControl id={id} required error={rest.error} component="fieldset">
          {!matches && <FormLabel component="legend">{label}</FormLabel>}
          <input
            accept="image/*"
            id={`image-upload-${label}`}
            multiple
            type="file"
            onChange={(event) => {
              if (event?.target?.files?.length) {
                const file = event.target.files[0];
                onChange(file);
              }
            }}
            style={{ display: 'none' }}
          />
          <label htmlFor={`image-upload-${label}`}>
            <Box
              height={200}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {value ? (
                typeof value === 'string' ? (
                  <Media
                    url={value as string}
                    onRemove={() => onChange(null)}
                  />
                ) : (
                  <Media file={value as File} onRemove={() => onChange(null)} />
                )
              ) : (
                <Person style={{ cursor: 'pointer', fontSize: 200 }} />
              )}
            </Box>
          </label>
          {rest.helperText && (
            <FormHelperText error={rest.error} style={{ textAlign: 'center' }}>
              {rest.helperText}
            </FormHelperText>
          )}
        </FormControl>
      );
    if (fieldType === 'date')
      return (
        <DatePicker
          id={id}
          value={value ? (value as Date) : null}
          onChange={onChange}
          disableFuture
          openTo={id === 'date_of_birth' ? 'year' : 'date'}
          format="dd/MM/yyyy"
          label={!matches ? label : undefined}
          views={id === 'date_of_birth' ? ['year', 'month', 'date'] : undefined}
          inputVariant="outlined"
          clearable
          required={rest.required}
          error={rest.error}
          helperText={rest.error}
        />
      );
  };
  return (
    <FormRow
      label={matches && label}
      labelProps={{ required: rest.required }}
      dense={dense}
      labelFlex={labelFlex}
    >
      {render()}
    </FormRow>
  );
};

export default withTranslation()(FormField);
