import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import {
  List,
  colors as materialColors,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  Badge,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import usePersistedState from '../../hooks/usePersistedState';
import { UserRole } from '../../types';

export interface NavItem {
  name: string;
  icon: typeof SvgIcon;
  href: string;
  items?: NavItem[];
  active?: boolean;
  accessibility?: UserRole[];
  badge?: typeof Badge;
}

export const CollapsedSize = 54;
const ExpandedSize = 250;

interface NavItemProps {
  item: NavItem;
}

function NavComponent({ item }: NavItemProps) {
  const [open, setOpen] = usePersistedState<boolean>(item.name, true);
  const handleToggle = useCallback(() => {
    setOpen((current) => !current);
  }, [setOpen]);
  const renderIcon = () => {
    if (item.icon) {
      let icon = <item.icon />;
      if (item.badge) {
        const CustomBadge = item.badge;
        icon = <CustomBadge>{icon}</CustomBadge>;
      }
      return <ListItemIcon>{icon}</ListItemIcon>;
    }
    return null;
  };
  return (
    <div>
      <ListItem
        button
        component={NavLink}
        to={item.href}
        activeClassName="Mui-selected"
        exact
      >
        {renderIcon()}
        <ListItemText primary={item.name} />
        {item.items && (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={handleToggle}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {item.items && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ItemsContainer>
            {item.items.map((subitem) => (
              <NavComponent item={subitem} key={item.name} />
            ))}
          </ItemsContainer>
        </Collapse>
      )}
      <Divider />
    </div>
  );
}

const ItemsContainer = styled.div`
  padding-left: 5px;
`;

interface MainMenuProps {
  items: NavItem[];
}

const MainMenu: React.FC<MainMenuProps> = ({ items }: MainMenuProps) => {
  return (
    <Navigation>
      <NavigationInner>
        <List>
          {items.map((item, i) => (
            <NavComponent item={item} key={i} />
          ))}
        </List>
      </NavigationInner>
    </Navigation>
  );
};

const Navigation = styled.nav`
  background-color: white;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 60px;
  width: ${CollapsedSize}px;
  border-right: 1px solid ${materialColors.grey[200]};
  transition: width 0.2s;
  z-index: 1000;
  :hover {
    width: ${ExpandedSize}px;
    box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border: none;
  }
`;

const NavigationInner = styled.div`
  width: ${ExpandedSize}px;
`;

export default MainMenu;
