export { default as colors } from './colors';

export const formOrdering: Record<string, number> = {
  name: 1,
  id_type: 2,
  id_no: 3,
  phone_no: 4,
  email: 5,
  password: 6,
  confirm_password: 7,
  created_at: 8,
  user_role: 9,
};

export const PASSWORD_CONSTRAINT = {
  length: {
    minimum: 6,
  },
};

export const constraints = {
  name: {
    presence: true,
  },
  email: { presence: true, email: true },
  password: {
    presence: true,
    ...PASSWORD_CONSTRAINT,
  },
  confirmPassword: {
    equality: 'password',
  },
  id_type: {
    presence: true,
  },
  id_no: {
    presence: true,
  },
  phone_no: {
    presence: true,
    format: {
      pattern: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/,
    },
  },
};
