import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import styled from 'styled-components';
import LanguageToggle from '../components/LanguageToggle';
import { LoginResponse } from '../types';
import PasswordFormField from '../components/PasswordFormField';
import { http } from '../utils/http';
import FormRow from '../components/FormRow';
import useValidate from '../hooks/useValidate';

type FormValues = {
  password: string;
  confirmPassword: string;
};
type FormErrors = Record<keyof FormValues, string | null>;

const constraints = {
  password: {
    presence: true,
    length: {
      minimum: 6,
    },
  },
  confirmPassword: {
    equality: 'password',
  },
};

const emptyErrorObj = {
  password: null,
  confirmPassword: null,
};
type TParams = { jwtToken: string };
const ResetPassword: React.FC<WithTranslation> = ({ t }) => {
  const {
    params: { jwtToken },
  } = useRouteMatch<TParams>();
  const [formValues, setFormValues] = useState<FormValues>({
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>(emptyErrorObj);
  const [loginError, setLoginError] = useState<string | null>(null);
  const validate = useValidate();

  const handleFormValueChange = (key: keyof FormValues, value: any) => {
    const newFormValues = { ...formValues, [key]: value };
    const check = key.toLowerCase().includes('password')
      ? {
          password:
            newFormValues.password !== '' ? newFormValues.password : null,
          confirmPassword: newFormValues.confirmPassword,
        }
      : { [key]: value !== '' ? value : null };
    const constraint = key.toLowerCase().includes('password')
      ? {
          password: constraints.password,
          confirmPassword: constraints.confirmPassword,
        }
      : { [key]: constraints[key] };
    const error = validate(check, constraint) || {};
    const currentError = key.toLowerCase().includes('password')
      ? { password: error.password, confirmPassword: error.confirmPassword }
      : { [key]: error[key] };
    const errors = { ...formErrors, ...currentError };
    setFormErrors(errors);
    setFormValues(newFormValues);
  };

  function postResetPassword() {
    const formData = new FormData();
    formData.append('password', formValues.password);
    formData.append('jwt_token', jwtToken);
    const request = new Request('/api/volunteer/reset_password', {
      method: 'POST',
      body: formData,
    });
    return http<LoginResponse>(request)
      .then(({ parsedBody: { success } = {} }) => {
        if (success) {
          setLoginError('password-reset-successful');
        }
      })
      .catch((e: Error) => {
        setLoginError(`error:${e.message}`);
      });
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LanguageToggle
        style={{ position: 'absolute', top: '1rem', right: '1rem' }}
      />
      {/* <Logo style={{ height: '40%', maxWidth: '100%' }} /> */}
      <Typography
        variant="h3"
        color="primary"
        gutterBottom
        style={{ marginTop: '4rem' }}
      >
        {t('reset-password')}
      </Typography>
      <FormContainer>
        <PasswordFormField
          required
          id="new-password"
          error={!!formErrors.password}
          helperText={formErrors.password}
          value={formValues.password}
          onChange={(value) => handleFormValueChange('password', value)}
          fullWidth
        />
        <PasswordFormField
          required
          id="confirm-password"
          error={!!formErrors.confirmPassword}
          helperText={formErrors.confirmPassword}
          label={t('confirmPassword')}
          value={formValues.confirmPassword}
          onChange={(value) => handleFormValueChange('confirmPassword', value)}
          fullWidth
        />
        <FormRow dense>
          <Button
            color="primary"
            variant="contained"
            disabled={validate(formValues, constraints)}
            onClick={postResetPassword}
            style={{ marginTop: 20 }}
          >
            {t('submit')}
          </Button>
        </FormRow>
        {loginError && (
          <FormRow dense>
            <FormHelperText style={{ textAlign: 'center' }} error>
              {t(loginError)}
            </FormHelperText>
          </FormRow>
        )}
        <FormRow dense>
          <Link to="/login" style={{ margin: 10, display: 'block' }}>
            {t('return-to-login')}
          </Link>
        </FormRow>
      </FormContainer>
    </Box>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default withTranslation()(ResetPassword);
