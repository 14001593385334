import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { UserRole, Volunteer } from '../../types';
import { WithTranslation, withTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints';
import { formatDate } from '../../utils/datetime';

type VolunteerRowProps = {
  volunteer: Volunteer;
  onChangeRole?: (id: string, role: UserRole) => void;
  onApproveOrReject?: (id: string, approve: boolean) => void;
} & WithTranslation;
const VolunteerRow = ({
  volunteer,
  t,
  onChangeRole,
  onApproveOrReject,
}: VolunteerRowProps) => {
  const { md } = useBreakpoints();

  return (
    <Card style={{ width: md ? '600px' : '270px', marginTop: 10 }}>
      <CardContent
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Avatar
          alt={volunteer.name}
          src={volunteer.photo_url ? `/api/${volunteer.photo_url}` : undefined}
          variant="square"
          style={{
            maxWidth: 140,
            margin: 5,
            width: 'auto',
            height: 140,
            flex: '0 0 140px',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          flex="1 0 auto"
          textAlign={md ? 'left' : 'center'}
          width={400}
          maxWidth="100%"
        >
          <Box flex="1 0 auto">
            <Typography gutterBottom variant="h5">
              {volunteer.name}
            </Typography>
            {volunteer.id_type && (
              <Typography variant="body2" color="textSecondary" component="p">
                {t(volunteer.id_type)}: {volunteer.id_no}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" component="p">
              {t('email')}: {volunteer.email}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('phone_no')}: {volunteer.phone_no || '-'}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('created_at')}:{' '}
              {volunteer.created_at ? formatDate(volunteer.created_at) : '-'}
            </Typography>
          </Box>
          {volunteer.user_role ? (
            <TextField
              select
              style={{ flexDirection: 'row-reverse' }}
              value={volunteer.user_role || 'viewer'}
              disabled={!onChangeRole}
              onChange={(e) => {
                if (onChangeRole)
                  onChangeRole(volunteer.id, e.target.value as UserRole);
              }}
            >
              {['viewer', 'editor', 'superadmin'].map((role) => (
                <MenuItem key={`options-${volunteer.id}-${role}`} value={role}>
                  {t(role)}
                </MenuItem>
              ))}
            </TextField>
          ) : onApproveOrReject ? (
            <Box
              style={{
                flexDirection: 'row-reverse',
                display: 'inline-flex',
                marginTop: 10,
              }}
            >
              <Button
                color="secondary"
                style={{ marginLeft: 5 }}
                variant="contained"
                onClick={() => onApproveOrReject(volunteer.id, false)}
              >
                Reject
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onApproveOrReject(volunteer.id, true)}
              >
                Approve
              </Button>
            </Box>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(VolunteerRow);
