import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { CreateClientPayload } from '../../types';
import { http } from '../../utils/http';
import ClientForm, { FormValues } from '../../components/ClientForm';

const Registration: React.FC<WithTranslation> = ({ t }) => {
  const [formValues, setFormValues] = useState<FormValues>({
    date_of_interview: new Date().toDateString(),
    is_eligible: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  function postRegister() {
    const formData = new FormData();
    const { photo, name, id_type, id_no, is_eligible, ...details } = formValues;
    Object.entries({
      name,
      id_type,
      id_no,
      is_eligible,
    }).forEach(([key, value]) => {
      if (value) formData.append(key, value.toString());
    });
    formData.append('photo', photo as File);
    formData.append('details', JSON.stringify(details));
    const request = new Request('/api/client', {
      method: 'POST',
      body: formData,
    });
    return http<CreateClientPayload>(request)
      .then(({ parsedBody: { success, error, client_id } = {} }) => {
        if (success) {
          enqueueSnackbar(
            t('backend-response:client-registration-successful'),
            {
              variant: 'success',
            }
          );
          history.push(`/client/${client_id}`);
          return true;
        } else {
          enqueueSnackbar(t(error || 'backend-response:registration-failed'), {
            variant: 'error',
          });
          return false;
        }
      })
      .catch((e) => {
        enqueueSnackbar(t(`error:${e.message}`), {
          variant: 'error',
        });
        return false;
      });
  }
  return (
    <ClientForm
      title={t('register-new-client')}
      formValues={formValues}
      setFormValues={setFormValues}
      submit={postRegister}
      submitLabel="register"
    />
  );
};

export default withTranslation()(Registration);
