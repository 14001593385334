import React from 'react';
import { Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  TimelineProps,
  TimelineDotProps,
} from '@material-ui/lab';
import useBreakpoints from '../hooks/useBreakpoints';

type TimelineItem = {
  time?: string;
  icon?: JSX.Element;
  content?: React.ReactNode;
};
type CustomizedTimelineProps = {
  items: TimelineItem[];
  timelineDotProps?: TimelineDotProps;
} & TimelineProps;
export default function CustomizedTimeline({
  items,
  timelineDotProps = {},
  ...timelineProps
}: CustomizedTimelineProps) {
  const { md } = useBreakpoints();
  return (
    <Timeline align={md ? 'alternate' : 'right'} {...timelineProps}>
      {items.map(({ time, icon, content }) => (
        <TimelineItem key={`timeline-item-${time}`}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {time}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot {...timelineDotProps}>{icon}</TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{content}</TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
