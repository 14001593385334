export class Store {
  data: Record<string, any>;

  constructor() {
    this.data = {};
  }

  add(key: string, value: any) {
    this.data[key] = value;
  }

  get(key: string): any | undefined {
    return this.data[key];
  }
}

export default new Store();
