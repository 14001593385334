import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { parse as parseQs, stringify } from 'query-string';
import { identity, isFunction, throttle } from 'lodash';
import { format, parse } from 'date-fns';

type UseQueryStringStateValue<T> = [T, Dispatch<SetStateAction<T>>];

export function useQueryStringState<T>(
  key: string,
  initialValue: T,
  fromString: (value: string) => T,
  toString: (value: T) => string
): UseQueryStringStateValue<T> {
  const [value, setValue] = useState(() => {
    const stringValue = getQueryStringValue(key);
    if (stringValue) {
      return fromString(stringValue);
    }
    return initialValue;
  });
  const onSetValue: Dispatch<SetStateAction<T>> = useCallback(
    (newValue: SetStateAction<T>) => {
      if (isFunction(newValue)) {
        setValue((current) => {
          const actualNewValue = newValue(current);
          throttledSetQueryStringValue(key, toString(actualNewValue));
          return actualNewValue;
        });
      } else {
        setValue(newValue);
        throttledSetQueryStringValue(key, toString(newValue));
      }
    },
    [key, toString]
  );

  return [value, onSetValue];
}

export function useQueryStringStateString(key: string, initialValue: string) {
  return useQueryStringState<string>(key, initialValue, identity, identity);
}

function stringToNumber(v: string): number {
  return +v;
}

function numberToString(v: number): string {
  return v.toString();
}

export function useQueryStringStateNumber(key: string, initialValue: number) {
  return useQueryStringState(key, initialValue, stringToNumber, numberToString);
}

function stringToBoolean(v: string): boolean {
  return v.toLowerCase() === 'true';
}

function booleanToString(v: boolean): string {
  return v.toString();
}

export function useQueryStringStateBoolean(key: string, initialValue: boolean) {
  return useQueryStringState(
    key,
    initialValue,
    stringToBoolean,
    booleanToString
  );
}

function stringToBooleanOrNull(v: string): boolean | null {
  return v.toLowerCase() === 'true' || v.toLowerCase() === 'null'
    ? null
    : false;
}

function booleanOrNullToString(v: boolean | null): string {
  return v?.toString() || 'null';
}

export function useQueryStringStateOptionalBoolean(
  key: string,
  initialValue: boolean | null
) {
  return useQueryStringState(
    key,
    initialValue,
    stringToBooleanOrNull,
    booleanOrNullToString
  );
}

function dateToString(date: Date | null): string {
  if (!date) {
    return '';
  }
  return format(date, 'yyyy-MM-dd');
}

function stringToDate(date: string): Date | null {
  if (!date) {
    return null;
  }
  return parse(date, 'yyyy-MM-dd', new Date());
}

export function useQueryStringStateStringArray(
  key: string,
  initialValue: string[]
) {
  return useQueryStringState<string[]>(
    key,
    initialValue,
    stringToStringArray,
    stringArrayToString
  );
}

function stringToStringArray(v: string): string[] {
  return v.split(',');
}

function stringArrayToString(v: string[]): string {
  return v.join(',');
}

/**
 * This is meant to store Date (without its time component)
 * @param key Query String key
 * @param initialValue Initial Date
 */
export function useQueryStringStateDate(
  key: string,
  initialValue: Date | null
) {
  return useQueryStringState(key, initialValue, stringToDate, dateToString);
}

const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.replaceState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (
  key: string,
  value: string,
  queryString = window.location.search
) => {
  const values = parseQs(queryString);
  const newQsValue = stringify({ ...values, [key]: value }, { encode: false });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const throttledSetQueryStringValue = throttle(setQueryStringValue, 500, {
  leading: true,
  trailing: true,
});

export const getQueryStringValue = (
  key: string,
  queryString = window.location.search
): string | null | undefined => {
  const values = parseQs(queryString, {
    parseBooleans: false,
    parseNumbers: false,
  });
  const parsed = values[key];
  if (Array.isArray(parsed)) {
    return parsed.join(',');
  }
  return parsed;
};
