import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ClientDetail } from '../../types';
import useBreakpoints from '../../hooks/useBreakpoints';
import { formatDateTime } from '../../utils/datetime';

type ClientProps = {
  client: ClientDetail;
  showClaimButton?: boolean;
  showEligibility?: boolean;
  toggleEligibility?: () => Promise<void>;
  showCardIssued?: boolean;
  toggleCardIssued?: () => Promise<void>;
} & WithTranslation;
const ClientCard = ({
  client,
  showClaimButton = false,
  t,
  showEligibility = false,
  toggleEligibility,
  showCardIssued = false,
  toggleCardIssued,
}: ClientProps) => {
  const { md } = useBreakpoints();

  return (
    <Card style={{ width: md ? '576px' : '270px' }}>
      <CardContent
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        <Avatar
          alt={client.name}
          src={client.photo_url ? `/api/${client.photo_url}` : undefined}
          variant="square"
          style={{
            maxWidth: 140,
            margin: 5,
            width: 'auto',
            height: 140,
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box flex="1 0 auto" textAlign={md ? 'left' : 'center'}>
            <Typography gutterBottom variant="h5" component="h2">
              {client.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {client.id_type}: {client.id_no}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('last-claimed-at')}:{' '}
              {client.last_claimed_at
                ? formatDateTime(client.last_claimed_at)
                : '-'}
            </Typography>
            {showEligibility && (
              <FormControlLabel
                style={{
                  display: 'flex',
                  justifyContent: md ? 'flex-end' : 'center',
                  marginLeft: 0,
                }}
                label={t('is_eligible')}
                labelPlacement="start"
                value={client.is_eligible}
                control={
                  <Switch color="primary" checked={client.is_eligible} />
                }
                onChange={() => {
                  if (toggleEligibility) toggleEligibility();
                }}
                disabled={!toggleEligibility}
              />
            )}
            {showCardIssued && (
              <FormControlLabel
                style={{
                  display: 'flex',
                  justifyContent: md ? 'flex-end' : 'center',
                  marginLeft: 0,
                }}
                label={t('card_issued')}
                labelPlacement="start"
                value={client.card_issued}
                control={
                  <Switch color="primary" checked={client.card_issued} />
                }
                onChange={() => {
                  if (toggleCardIssued) toggleCardIssued();
                }}
                disabled={!toggleCardIssued}
              />
            )}
          </Box>
          {showClaimButton && (
            <Button
              component={Link}
              to={`/claim/${client.id}`}
              color="primary"
              variant="contained"
              style={{ marginTop: 25 }}
            >
              {t('claim-now')}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(ClientCard);
