import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import useFetch from '../hooks/useFetch';
import { GetFormResponse } from '../types';
import FormField from './FormField';
import useValidate from '../hooks/useValidate';
import Loading from './Loading';
import useBreakpoints from '../hooks/useBreakpoints';

type FormValueType =
  | string
  | string[]
  | boolean
  | null
  | number
  | File
  | undefined;
export type FormValues = {
  [key: string]: FormValueType;
};
type FormErrors = Record<keyof FormValues, string | null>;
type ClientFormProps = {
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  submit: () => Promise<boolean>;
  submitLabel?: string;
  cancelTo?: string;
  title: string;
  loading?: boolean;
};
const ClientForm: React.FC<WithTranslation & ClientFormProps> = ({
  t,
  title,
  formValues,
  setFormValues,
  submit,
  submitLabel = 'submit',
  cancelTo,
  loading: dataLoading = false,
}) => {
  const [{ form: formFields }, loading] = useFetch<GetFormResponse>(
    `/api/form`,
    {
      form: [],
      success: false,
    }
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const validate = useValidate();
  const handleFormValueChange = (
    key: keyof FormValues,
    value: FormValueType,
    constraints: any
  ) => {
    const newFormValues = { ...formValues, [key]: value };
    const check = { [key]: value !== '' ? value : null };
    const constraint = { [key]: constraints[key] };
    const error = validate(check, constraint) || {};
    const currentError = { [key]: error[key] };
    const errors = { ...formErrors, ...currentError };
    setFormErrors(errors);
    setFormValues(newFormValues);
  };
  const { xl } = useBreakpoints();
  if (loading || dataLoading) <Loading />;

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4" color="primary" gutterBottom>
        {title}
      </Typography>
      <Card
        variant="outlined"
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          width: xl ? '900px' : '80%',
          minWidth: '300px',
        }}
      >
        <CardContent>
          <Typography variant="caption">{t('required-fields')}</Typography>
          {formFields
            .sort((a, b) => a.ordering - b.ordering)
            .map(
              ({
                field_name,
                field_type,
                id,
                details: { validation, options, default_value },
              }) => (
                <FormField
                  id={field_name}
                  key={`client-registration-${id}`}
                  fieldType={field_type}
                  options={options?.map((option) => ({
                    label: t(`form:${field_name}.${option}`),
                    value: option,
                  }))}
                  required={validation.presence}
                  error={!!formErrors[field_name]}
                  helperText={formErrors[field_name]}
                  label={t(`form:${field_name}.title`)}
                  fullWidth
                  value={formValues[field_name] || default_value || ''}
                  labelFlex="0 0 300px"
                  onChange={(value) =>
                    handleFormValueChange(field_name, value, {
                      [field_name]: validation,
                    })
                  }
                />
              )
            )}
        </CardContent>
        <CardActions style={{ flexDirection: 'column' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={
              !!validate(
                formValues,
                formFields.reduce(
                  (obj, field) => ({
                    ...obj,
                    [field.field_name]: field.details.validation,
                  }),
                  {}
                )
              )
            }
            onClick={submit}
            style={{ marginTop: 20 }}
          >
            {t(submitLabel)}
          </Button>
          <Link
            to={cancelTo || '/clients'}
            style={{ margin: 10, display: 'block' }}
            onClick={() => {
              setFormValues({});
            }}
          >
            {t('cancel')}
          </Link>
        </CardActions>
      </Card>
    </Box>
  );
};

export default withTranslation()(ClientForm);
