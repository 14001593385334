import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import qs from 'query-string';
import { useSnackbar } from 'notistack';
import useFetch from '../../hooks/useFetch';
import { Volunteer, Paginated, UserRole } from '../../types';
import {
  useQueryStringStateBoolean,
  useQueryStringStateNumber,
  useQueryStringStateString,
} from '../../hooks/useQueryStringState';
import { http } from '../../utils/http';
import CustomTable from '../../components/CustomTable';
import VolunteerRow from './VolunteerRow';

// type VolunteersListProps = {};
const VolunteersList: React.FC<WithTranslation> = ({ t }) => {
  const [itemsPerPage, setItemsPerPage] = useQueryStringStateNumber(
    'items-per-page',
    10
  );
  const [page, setPage] = useQueryStringStateNumber('page', 1);
  const [sortBy, setSortBy] = useQueryStringStateString(
    'sort-by',
    'created_at'
  );
  const [isAscending, setIsAscending] = useQueryStringStateBoolean(
    'is-ascending',
    false
  );
  const [searchText, setSearchText] = useQueryStringStateString('search', '');

  const [volunteerlist, loading] = useFetch<Paginated<Volunteer>>(
    `/api/volunteers?${qs.stringify({
      items_per_page: itemsPerPage,
      page,
      sort_by: sortBy,
      is_ascending: isAscending,
      search: searchText || undefined,
    })}`,
    {
      items: [],
      pages: 0,
      returned_page: 0,
      success: false,
      total_items: 0,
      all_ids: [],
    }
  );
  const [volunteers, setVolunteers] = useState<Volunteer[]>(
    volunteerlist.items
  );

  useEffect(() => {
    setVolunteers(volunteerlist.items);
  }, [volunteerlist]);
  const { enqueueSnackbar } = useSnackbar();

  const changeRole = useCallback(
    (volunteerId: string, role: UserRole) => {
      const formData = new FormData();
      formData.append('user_role', role);
      const request = new Request(`/api/volunteer/${volunteerId}/change_role`, {
        method: 'PUT',
        body: formData,
      });
      return http<{ success: boolean; user_role: UserRole }>(request)
        .then(({ statusText, parsedBody: { success, user_role } = {} }) => {
          if (success) {
            setVolunteers((volunteers) =>
              volunteers.map((volunteer) => {
                if (volunteer.id === volunteerId)
                  return {
                    ...volunteer,
                    user_role: user_role || 'viewer',
                  };
                return volunteer;
              })
            );
          } else {
            enqueueSnackbar(statusText, { variant: 'error' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return (
    <CustomTable
      data={volunteers}
      loading={loading}
      rowComponent={(volunteer) => (
        <VolunteerRow
          volunteer={volunteer as Volunteer}
          key={`list-row-${(volunteer as Volunteer).id}`}
          onChangeRole={changeRole}
        />
      )}
      title={t('volunteer-list')}
      page={page}
      rowsPerPage={itemsPerPage}
      search={true}
      searchText={searchText}
      selectableRows={false}
      rowsPerPageOptions={[10, 20, 50, 100]}
      sortColumns={['name', 'created_at'].map((value) => ({
        label: t(value),
        value,
      }))}
      sortOrder={{ [sortBy]: isAscending ? 'asc' : 'desc' }}
      count={volunteerlist.total_items}
      onChangeRowsPerPage={(numberOfRows) => setItemsPerPage(numberOfRows)}
      onChangePage={(currentPage) => setPage(currentPage)}
      onColumnSortChange={(changedColumn, direction) => {
        setSortBy(changedColumn);
        setIsAscending(direction === 'asc');
      }}
      onSearchChange={(searchText) => {
        setSearchText(searchText || '');
      }}
    />
  );
};

export default withTranslation()(VolunteersList);
