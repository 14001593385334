const config = {
  i18n: {
    whitelist: ['en'],
    fallbackLng: 'en',
    //debug: true,
    load: 'currentOnly',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'error', 'backend-response', 'muiDataTable', 'form'],
    backend: {
      loadPath: '/api/locales/{{lng}}/{{ns}}',
    },
  },
};

export default config;
