import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { groupBy } from 'lodash/fp';
import { Bar } from 'react-chartjs-2';
import useFetch from '../../hooks/useFetch';
import { ClaimLogsResponse } from '../../types/claim_logs';

import Loading from '../../components/Loading';
import { Typography } from '@material-ui/core';

const ClaimHistory: React.FC<WithTranslation> = ({ t }) => {
  const [{ claim_logs }, loading] = useFetch<ClaimLogsResponse>(
    '/api/claimlogs',
    {
      success: false,
      claim_logs: [],
    }
  );

  if (loading) return <Loading />;
  const claims = groupBy(
    (claim) => new Date(claim.claimed_at).toDateString(),
    claim_logs
  );
  console.log(claims);
  return (
    <div style={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}>
      <Typography variant="h1" style={{ marginBottom: 10 }}>
        {t('claim-history')}
      </Typography>
      <Bar
        data={{
          labels: Object.keys(claims),
          datasets: [
            {
              label: 'No of claims',
              data: Object.values(claims).map((c) => c.length),
              maxBarThickness: 50,
            },
          ],
        }}
        options={{
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 2,
              backgroundColor: 'rgb(194,60,50, 0.2)',
              borderColor: 'rgb(194,60,50)',
            },
          },
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: t('no-of-claims'),
              },
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};
export default withTranslation()(ClaimHistory);
