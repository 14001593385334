import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Link, Typography } from '@material-ui/core';
import styled from 'styled-components';
import FormField from '../../components/FormField';
import { IdType } from '../../types';
import useValidate from '../../hooks/useValidate';
import { constraints } from '../../constants';
import { isEqual } from 'lodash';

const idTypes: IdType[] = ['malaysian-ic', 'passport'];
type FormValues = {
  name: string;
  email: string;
  phone_no: string;
  id_type: IdType;
  id_no: string;
};
type FormErrors = Record<keyof FormValues, string | null>;

const emptyErrorObj = {
  name: null,
  email: null,
  phone_no: null,
  id_type: null,
  id_no: null,
};

type EditVolunteerFormProps = {
  values: FormValues;
  postUpdate: (updatedValues: FormValues) => Promise<void>;
  goBack?: () => void;
} & WithTranslation;
const EditVolunteerForm: React.FC<EditVolunteerFormProps> = ({
  values,
  t,
  postUpdate,
  goBack,
}) => {
  const [formValues, setFormValues] = useState<FormValues>(values);
  useEffect(() => {
    setFormValues(values);
  }, [values]);
  const [formErrors, setFormErrors] = useState<FormErrors>(emptyErrorObj);
  const validate = useValidate();

  const handleFormValueChange = (key: keyof FormValues, value: any) => {
    const newFormValues = { ...formValues, [key]: value };
    const check = { [key]: value !== '' ? value : null };
    const constraint = { [key]: constraints[key] };
    const error = validate(check, constraint) || {};
    const currentError = { [key]: error[key] };
    const errors = { ...formErrors, ...currentError };
    setFormErrors(errors);
    setFormValues(newFormValues);
  };

  return (
    <FormContainer>
      <Typography variant="caption">{t('required-fields')}</Typography>
      <FormField
        id="user_name"
        required
        error={!!formErrors.name}
        helperText={formErrors.name}
        label={t('name')}
        placeholder="Ong Lee Han"
        fullWidth
        value={formValues.name}
        onChange={(value) => handleFormValueChange('name', value)}
      />
      <FormField
        id="user_id_type"
        required
        error={!!formErrors.id_type}
        helperText={formErrors.id_type}
        label={t('id_type')}
        fieldType="single_select"
        value={formValues.id_type}
        onChange={(value) => handleFormValueChange('id_type', value)}
        fullWidth
        options={idTypes.map((option) => ({
          label: t(option),
          value: option,
        }))}
      />
      <FormField
        id="user_id_no"
        required
        error={!!formErrors.id_no}
        helperText={formErrors.id_no}
        label={t('id_no')}
        placeholder="880803111234"
        value={formValues.id_no}
        onChange={(value) => handleFormValueChange('id_no', value)}
        fullWidth
      />
      <FormField
        id="user_phone_no"
        required
        error={!!formErrors.phone_no}
        helperText={formErrors.phone_no}
        label={t('phone_no')}
        placeholder="+60123456789"
        value={formValues.phone_no}
        onChange={(value) => handleFormValueChange('phone_no', value)}
        fullWidth
      />
      <FormField
        id="user_email"
        required
        type="email"
        error={!!formErrors.email}
        helperText={formErrors.email}
        label={t('email')}
        placeholder="email@email.com"
        value={formValues.email}
        onChange={(value) => handleFormValueChange('email', value)}
        fullWidth
      />
      <FormFooter>
        <Button
          color="primary"
          variant="contained"
          disabled={
            validate(formValues, {
              name: constraints.name,
              email: constraints.email,
              id_type: constraints.id_type,
              id_no: constraints.id_no,
              phone_no: constraints.phone_no,
            }) || isEqual(formValues, values)
          }
          onClick={() => postUpdate(formValues)}
          style={{ marginTop: 20 }}
        >
          {t('update')}
        </Button>
        <Link
          style={{ cursor: 'pointer', marginTop: 5 }}
          onClick={() => {
            setFormValues(values);
            if (goBack) goBack();
          }}
          color="secondary"
        >
          {t('cancel')}
        </Link>
      </FormFooter>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withTranslation()(EditVolunteerForm);
