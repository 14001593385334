import React from 'react';
import { ClientDetail } from '../../types';
import FormRow from '../../components/FormRow';
import { Typography } from '@material-ui/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isArray } from 'lodash';

type ClientDetailViewProps = { client: ClientDetail };
const ClientDetailView: React.FC<WithTranslation & ClientDetailViewProps> = ({
  client,
  t,
}) => {
  const renderValue = (field: string, value: any) => {
    if (isArray(value)) {
      return (value as string[]).join(', ');
    }
    if (field === 'date_of_birth') {
      return new Date(value).toDateString();
    }
    return value;
  };
  return (
    <>
      <Typography variant="h6" style={{ textAlign: 'center', marginTop: 10 }}>
        {t('details')}:-
      </Typography>
      {Object.entries(client.details).map(([key, value]) => (
        <FormRow
          key={`${client.id}-${key}`}
          label={t(`form:${key}.title`)}
          labelFlex="0 0 50%"
          contentTextAlign="left"
        >
          {renderValue(key, value)}
        </FormRow>
      ))}
    </>
  );
};

export default withTranslation()(ClientDetailView);
