import React, { CSSProperties, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useAuth } from '../context/auth';
import { http } from '../utils/http';
import { LogoutResponse } from '../types';

type LogoutButtonProps = {
  style?: CSSProperties;
} & WithTranslation;

const LogoutButton: React.FC<LogoutButtonProps> = ({ style, t }) => {
  const { setUserLoginDetails } = useAuth();
  const history = useHistory();

  const handleLogOut = useCallback(() => {
    const request = new Request('/api/volunteer/logout', {
      method: 'POST',
    });
    return http<LogoutResponse>(request)
      .then(({ parsedBody: { success } = {} }) => {
        if (success) {
          setUserLoginDetails(null);
          history.push('/login');
        } else {
          console.log('error:login.failed');
        }
      })
      .catch((e) => {
        console.log(`error:${e.message}`);
      });
  }, [setUserLoginDetails, history]);

  return (
    <Button color="secondary" onClick={handleLogOut} style={style}>
      {t('logout')}
    </Button>
  );
};

export default withTranslation()(LogoutButton);
