import { Box, Button } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

type WebCamCaptureProps = {
  setImage: Dispatch<SetStateAction<string | null>>;
  width?: number;
  height?: number;
} & WithTranslation;
const WebCamCapture = ({ setImage, width, height, t }: WebCamCaptureProps) => {
  const webcamRef = React.useRef<Webcam>(null);

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }
  }, [webcamRef, setImage]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Webcam
        audio={false}
        height={height || 300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={width || 300}
        videoConstraints={{
          width: width || 300,
          height: height || 300,
          facingMode: 'environment',
        }}
      />
      <Button
        onClick={capture}
        variant="outlined"
        color="primary"
        style={{ marginTop: 5 }}
      >
        {t('capture')}
      </Button>
    </Box>
  );
};

export default withTranslation()(WebCamCapture);
