import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    htmlFontSize: 16, // value of 1rem
    fontSize: 16,
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
    },
  },
  palette: {
    background: {
      default: '#FFFF',
    },
    primary: {
      light: '#FEEFEF',
      main: '#C23C32',
      contrastText: '#FFFF',
    },
    secondary: {
      light: '#AEAEAE',
      main: '#2D2A26',
      contrastText: '#FFFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: '#197D38',
      },
    },
  },
});

const Theme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
};

export default Theme;
