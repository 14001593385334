import { useState, useEffect, useRef } from 'react';
import store from '../utils/store';

export type NonNullFetchResponse<T> = [T, boolean, Error | null];
export type ContentType = 'json' | 'text' | 'blob';
export type OptionsType = {
  contentType?: ContentType;
  useCache?: boolean;
};

export const getResponse = (response: Response, contentType: ContentType) => {
  switch (contentType) {
    case 'text':
      return response.text();
    case 'blob':
      return response.blob();
    case 'json':
    default:
      return response.json();
  }
};

function useFetch<T>(
  url: string | null,
  defaultValue: T,
  options: OptionsType = {}
): NonNullFetchResponse<T> {
  const abort = useRef<AbortController | null>(null);
  const { contentType = 'json', useCache = false } = options;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T>(defaultValue);

  useEffect(() => {
    async function run() {
      if (url) {
        try {
          if (useCache) {
            const exists = store.get(url);
            if (exists) {
              setData(exists);
              setLoading(false);
              return;
            }
          }
          if (abort.current) {
            abort.current.abort();
          }
          abort.current = new AbortController();
          const response = await fetch(url, { signal: abort.current.signal });
          if (response.ok) {
            const responseData = await getResponse(response, contentType);
            setData(responseData);
            store.add(url, responseData);
            setError(null);
          } else {
            setError(new Error('Fetching data failed'));
          }
        } catch (error) {
          setError(new Error('Fetching data failed'));
        }
        setLoading(false);
      }
    }
    if (url) {
      setLoading(true);
      run();
    } else {
      setLoading(false);
      setError(null);
    }
  }, [url, contentType, useCache]);

  return [data, loading, error];
}

export default useFetch;
