import React from 'react';
import ClientsList from './ClientsList';

const Clients = () => {
  return (
    <>
      <ClientsList />
    </>
  );
};

export default Clients;
