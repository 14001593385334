import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Switch,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ClientDetail } from '../../types';
import { WithTranslation, withTranslation } from 'react-i18next';
import useBreakpoints from '../../hooks/useBreakpoints';
import { formatDateTime } from '../../utils/datetime';

type ClientRowProps = {
  client: ClientDetail;
  toggleEligibility?: (clientId: string) => Promise<void>;
  toggleCardIssued?: (clientId: string) => Promise<void>;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
} & WithTranslation;
const ClientRow = ({
  client,
  t,
  toggleEligibility,
  toggleCardIssued,
  selectable = false,
  selected = false,
  onSelect,
}: ClientRowProps) => {
  const { md } = useBreakpoints();

  return (
    <Card
      style={{
        width: md ? '600px' : '270px',
        marginTop: 10,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {selectable && onSelect && (
        <Checkbox
          color="primary"
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
        />
      )}
      <CardContent
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '100%',
          flex: '1 0',
        }}
      >
        <Avatar
          alt={client.name}
          src={client.photo_url ? `/api/${client.photo_url}` : undefined}
          variant="square"
          style={{
            maxWidth: 140,
            margin: `0 10px ${md ? '0' : '10px'} 0`,
            width: 'auto',
            height: 140,
            flex: '0 0 140px',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          flex="1 0 270px"
          textAlign={md ? 'left' : 'center'}
          width={400}
          maxWidth="100%"
        >
          <Box flex="1 0 auto">
            <Typography
              gutterBottom
              variant="h5"
              component={Link}
              to={`/client/${client.id}`}
            >
              {client.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {client.id_type}: {client.id_no}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {t('last-claimed-at')}:{' '}
              {client.last_claimed_at
                ? formatDateTime(client.last_claimed_at)
                : '-'}
            </Typography>
          </Box>
          <FormControlLabel
            label={t('is_eligible')}
            labelPlacement="start"
            value={client.is_eligible}
            control={<Switch color="primary" checked={client.is_eligible} />}
            onChange={() => {
              if (toggleEligibility) toggleEligibility(client.id);
            }}
            disabled={!toggleEligibility}
          />
          {toggleCardIssued && (
            <FormControlLabel
              label={t('card_issued')}
              labelPlacement="start"
              value={client.card_issued}
              control={<Switch color="primary" checked={client.card_issued} />}
              onChange={() => {
                toggleCardIssued(client.id);
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(ClientRow);
