import React, { CSSProperties, useCallback, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import config from '../config';
import i18next, { changeLocale } from '../i18n';

interface LanguageToggleProps {
  style?: CSSProperties;
}

const LanguageToggle: React.FC<LanguageToggleProps> = ({ style }) => {
  const [lng, setLng] = useState<string>(i18next.language);

  const handleLngChange = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, newValue: any) => {
      if (newValue) changeLocale(newValue, () => setLng(newValue));
    },
    []
  );

  return (
    <>
      {config.i18n.whitelist.length > 1 && (
        <ToggleButtonGroup
          value={lng}
          style={style}
          exclusive
          onChange={handleLngChange}
        >
          {config.i18n.whitelist.map((lng) => (
            <ToggleButton key={lng} value={lng}>
              {lng}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  );
};

export default LanguageToggle;
