import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Theme from './Theme';
import { init as i18nInit } from './i18n';
import { SnackbarProvider } from 'notistack';
import Loading from './components/Loading';

i18nInit();
ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <Suspense fallback={<Loading />}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Suspense>
    </Theme>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
