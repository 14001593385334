import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import FormField from '../components/FormField';
import { IdType, RegistrationResponse } from '../types';
import LanguageToggle from '../components/LanguageToggle';
import useValidate from '../hooks/useValidate';
import PasswordFormField from '../components/PasswordFormField';
import { http } from '../utils/http';
import { constraints } from '../constants';

const idTypes: IdType[] = ['malaysian-ic', 'passport'];
type FormValues = {
  name: string;
  email: string;
  phone_no: string;
  password: string;
  confirmPassword: string;
  id_type: IdType;
  id_no: string;
};
type FormErrors = Record<keyof FormValues, string | null>;

const emptyErrorObj = {
  name: null,
  email: null,
  phone_no: null,
  password: null,
  confirmPassword: null,
  id_type: null,
  id_no: null,
};

type RegistrationMsg = {
  error: boolean;
  message: string;
};

const Signup: React.FC<WithTranslation> = ({ t }) => {
  const [registerMsg, setRegisterMsg] = useState<RegistrationMsg | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    phone_no: '',
    password: '',
    confirmPassword: '',
    id_type: 'malaysian-ic',
    id_no: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>(emptyErrorObj);
  const validate = useValidate();

  const handleFormValueChange = (key: keyof FormValues, value: any) => {
    const newFormValues = { ...formValues, [key]: value };
    const check = key.toLowerCase().includes('password')
      ? {
          password:
            newFormValues.password !== '' ? newFormValues.password : null,
          confirmPassword: newFormValues.confirmPassword,
        }
      : { [key]: value !== '' ? value : null };
    const constraint = key.toLowerCase().includes('password')
      ? {
          password: constraints.password,
          confirmPassword: constraints.confirmPassword,
        }
      : { [key]: constraints[key] };
    const error = validate(check, constraint) || {};
    const currentError = key.toLowerCase().includes('password')
      ? { password: error.password, confirmPassword: error.confirmPassword }
      : { [key]: error[key] };
    const errors = { ...formErrors, ...currentError };
    setFormErrors(errors);
    setFormValues(newFormValues);
  };

  function postRegister() {
    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('password', formValues.password);
    formData.append('email', formValues.email);
    formData.append('id_no', formValues.id_no);
    formData.append('id_type', formValues.id_type);
    formData.append('phone_no', formValues.phone_no);
    const request = new Request('/api/volunteer', {
      method: 'POST',
      body: formData,
    });
    return http<RegistrationResponse>(request)
      .then(({ parsedBody: { success } = {} }) => {
        if (success) {
          setRegisterMsg({
            error: false,
            message: 'backend-response:volunteer-registration-successful',
          });
        } else {
          setRegisterMsg({
            error: true,
            message: 'backend-response:registration-failed',
          });
        }
      })
      .catch((e) => {
        setRegisterMsg({
          error: true,
          message: `error:${e.message}`,
        });
      });
  }

  return (
    <Box
      width="100%"
      height="100vh"
      overflow="scroll"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <LanguageToggle
        style={{ position: 'absolute', top: '1rem', right: '1rem' }}
      />
      <Typography
        variant="h2"
        color="primary"
        gutterBottom
        style={{ marginTop: '4rem' }}
      >
        {t('sign-up-page-title')}
      </Typography>
      <Card variant="outlined" style={{ padding: 10, overflow: 'initial' }}>
        <FormContainer>
          <Typography variant="caption">{t('required-fields')}</Typography>
          <FormField
            id="username"
            required
            error={!!formErrors.name}
            helperText={formErrors.name}
            label={t('name')}
            placeholder="Ong Lee Han"
            fullWidth
            value={formValues.name}
            onChange={(value) => handleFormValueChange('name', value)}
          />
          <FormField
            id="user_id_type"
            required
            error={!!formErrors.id_type}
            helperText={formErrors.id_type}
            label={t('id_type')}
            fieldType="single_select"
            value={formValues.id_type}
            onChange={(value) => handleFormValueChange('id_type', value)}
            fullWidth
            options={idTypes.map((option) => ({
              label: t(option),
              value: option,
            }))}
          />
          <FormField
            id="user_id_no"
            required
            error={!!formErrors.id_no}
            helperText={formErrors.id_no}
            label={t('id_no')}
            placeholder="880803111234"
            value={formValues.id_no}
            onChange={(value) => handleFormValueChange('id_no', value)}
            fullWidth
          />
          <FormField
            id="user_phone_no"
            required
            error={!!formErrors.phone_no}
            helperText={formErrors.phone_no}
            label={t('phone_no')}
            placeholder="+60123456789"
            value={formValues.phone_no}
            onChange={(value) => handleFormValueChange('phone_no', value)}
            fullWidth
          />
          <FormField
            id="user_email"
            required
            type="email"
            error={!!formErrors.email}
            helperText={formErrors.email}
            label={t('email')}
            placeholder="email@email.com"
            value={formValues.email}
            onChange={(value) => handleFormValueChange('email', value)}
            fullWidth
          />
          <PasswordFormField
            id="new-password"
            autocomplete="new-password"
            required
            error={!!formErrors.password}
            helperText={formErrors.password}
            value={formValues.password}
            onChange={(value) => handleFormValueChange('password', value)}
            fullWidth
          />
          <PasswordFormField
            id="new-password-confirm"
            autocomplete="new-password"
            required
            error={!!formErrors.confirmPassword}
            helperText={formErrors.confirmPassword}
            label={t('confirmPassword')}
            value={formValues.confirmPassword}
            onChange={(value) =>
              handleFormValueChange('confirmPassword', value)
            }
            fullWidth
          />
          <FormFooter>
            <Button
              color="primary"
              variant="contained"
              disabled={validate(formValues, constraints)}
              onClick={postRegister}
              style={{ marginTop: 20 }}
            >
              {t('register')}
            </Button>
            {registerMsg && (
              <FormHelperText
                style={{ textAlign: 'center' }}
                error={registerMsg.error}
              >
                {t(registerMsg.message)}
              </FormHelperText>
            )}
            <Link to="/login" style={{ margin: 10, display: 'block' }}>
              {t('already-have-an-account')}
            </Link>
          </FormFooter>
        </FormContainer>
      </Card>
    </Box>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default withTranslation()(Signup);
