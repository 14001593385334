import React, { CSSProperties } from 'react';
import { Box, useTheme } from '@material-ui/core';

type DotsProps = {
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
};
export const Dots = (props: DotsProps) => {
  const theme = useTheme();
  return (
    <svg
      style={props.style}
      width={props.width || '100%'}
      height={props.height || '100%'}
      viewBox="0 0 120 30"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color || theme.palette.primary.main}
    >
      <circle cx="15" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;0;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        cx="60"
        cy="15"
        r="9"
        attributeName="fillOpacity"
        from="1"
        to="0.3"
      >
        <animate
          attributeName="r"
          from="0"
          to="0"
          begin="0s"
          dur="0.8s"
          values="0;15;0"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="105" cy="15" r="15">
        <animate
          attributeName="r"
          from="15"
          to="15"
          begin="0s"
          dur="0.8s"
          values="15;0;15"
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fillOpacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

type LoadingProps = {
  style?: CSSProperties;
  loading?: boolean;
};
const Loading: React.FC<LoadingProps> = ({
  style,
  children,
  loading = true,
}) => {
  return (
    <>
      {loading ? (
        <Box
          width="100%"
          height="calc(100vh - 96px)"
          padding={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={style}
        >
          <Dots width="10rem" height="10rem" />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default Loading;
