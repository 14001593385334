import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { UserRole } from '../types';

type PrivateRouteProps = {
  roles?: UserRole[];
} & RouteProps;
function PrivateRoute({
  component: Component,
  roles,
  ...rest
}: PrivateRouteProps) {
  const { user } = useAuth();
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          !roles || roles.includes(user.userRole) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
